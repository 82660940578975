import { Fragment, useEffect, useMemo, useRef } from "react";

import { useFormik } from "formik";
import { isValidNumber } from "libphonenumber-js";
import { useTranslation } from "react-i18next";
import { MdAdd, MdChevronRight, MdDelete } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Col, Label, Row } from "reactstrap";
import * as Yup from "yup";

import PRButton from "~components/Generic/PRButton";
import PRContainer from "~components/Generic/PRContainer";
import PRDate from "~components/Generic/PRDate";
import PRFormFeedback from "~components/Generic/PRFormFeedback";
import PRInput from "~components/Generic/PRInput";
import PRNumber from "~components/Generic/PRNumber";
import PRPage from "~components/Generic/PRPage";
import PRSelect from "~components/Generic/PRSelect";
import PalPhoneNumber from "~components/mui/PalPhoneNumber";
import { organizationMemberFields, organizationVisibilityType } from "~constants";
import DateHelper from "~helpers/DateHelper";
import debounceAsync from "~helpers/debounceAsync";
import HistoryHelper from "~helpers/HistoryHelper";
import {
  createOrUpdateMember,
  getMember,
  getMemberFieldFormatList,
  getMembers,
  setMember,
} from "~store/organization/actions";
import { selectMember, selectMemberFieldFormat } from "~store/organization/selectors";
import { selectCurrentProject } from "~store/user/selectors";

export default function CustomerEdit() {
  const { t } = useTranslation();
  const { id } = useParams();
  const dispatch = useDispatch();
  const memberFieldFormat = useSelector(selectMemberFieldFormat);
  const currentProject = useSelector(selectCurrentProject);
  const member = useSelector(selectMember);
  const countryNameMapRef = useRef({});

  const memberDataFields = memberFieldFormat?.member_data_fields;

  const sortedMemberDataFields = useMemo(() => {
    return [...memberDataFields]?.sort((a, b) => a.order - b.order);
  }, [memberDataFields]);

  useEffect(() => {
    dispatch(getMemberFieldFormatList(currentProject.id));
    if (id) {
      dispatch(getMember(currentProject.id, id));
      return () => {
        dispatch(setMember(null));
      };
    }
  }, [dispatch, currentProject.id, id]);

  const getValidationObject = (visibilityType) => {
    let targetedMemberDataFields = memberDataFields;

    if (visibilityType === organizationVisibilityType.MEMBER_ONLY) {
      targetedMemberDataFields = memberDataFields?.filter(
        (item) => item.visibility_type === organizationVisibilityType.MEMBER_ONLY
      );
    }

    let validateField = targetedMemberDataFields?.reduce((acc, item) => {
      const type = item.field_type;
      try {
        let validation = Yup.mixed().nullable();
        if (type === organizationMemberFields.EMAIL) {
          validation = Yup.string().nullable().email("Invalid email");
        } else if ([organizationMemberFields.FLOAT, organizationMemberFields.INTEGER].includes(type)) {
          validation = Yup.number().nullable();
        } else if (organizationMemberFields.PHONE_NUMBER === type) {
          validation = Yup.string()
            .nullable()
            .test("len", t("component.formik.notValid").format(t("common.phoneNumber")), (val) => {
              if (!val) return true;
              let isValid = false;
              try {
                const country = countryNameMapRef.current[item.name];
                isValid = isValidNumber(val, country);
              } catch (e) {}

              return isValid;
            });
        } else if ([organizationMemberFields.INTEGER_ARRAY, organizationMemberFields.STRING_ARRAY].includes(type)) {
          validation = Yup.array()
            .nullable()
            .of(Yup.mixed().nullable())
            .test("unique", t("dashboard.customerEdit.duplicateValues"), (value = []) => {
              const unique = [...new Set(value)];
              return (value?.length || 0) === unique.length;
            })
            .test("item-length", t("component.formik.genericMinLength").format("1"), (value = []) => {
              if (!value?.length) return true;
              return value?.every((item) => item?.length > 1);
            });
        }
        if (item.is_required) {
          validation = validation.required(t("common.required"));
          if ([organizationMemberFields.INTEGER_ARRAY, organizationMemberFields.STRING_ARRAY].includes(type)) {
            validation = validation.min(1, t("dashboard.customerEdit.minArrayLength").format("1"));
          }
        }
        acc[item.name] = validation;
      } catch (error) {
        console.log(error);
      }
      return acc;
    }, {});
    return validateField;
  };

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: true,
    initialValues: {
      ...member,
      safe_information: {},
      member_only_information: {},
    },
    validationSchema: Yup.object().shape({
      safe_information: Yup.object().shape(getValidationObject()),
      member_only_information: Yup.object().shape(getValidationObject(organizationVisibilityType.MEMBER_ONLY)),
    }),

    onSubmit: async (values) => {
      const data = {
        ...values,
      };

      // TODO: fix on backend. replace true/false values with "True"/"False"
      for (const dataKey in data.safe_information) {
        const dataFormat = memberDataFields.find((item) => item.name === dataKey);
        const dataItem = data.safe_information[dataKey];
        if (dataFormat.field_type === organizationMemberFields.BOOL) {
          data.safe_information[dataKey] = dataItem ? "True" : "False";
        }
      }
      for (const dataKey in data.member_only_information) {
        const dataFormat = memberDataFields.find((item) => item.name === dataKey);
        const dataItem = data.member_only_information[dataKey];
        if (dataFormat.field_type === organizationMemberFields.BOOL) {
          data.member_only_information[dataKey] = dataItem ? "True" : "False";
        }
      }

      //discard unmodified member_only_information fields
      for (const dataKey in data.member_only_information) {
        if (data.member_only_information[dataKey] === member?.member_only_information?.[dataKey]) {
          delete data.member_only_information[dataKey];
        }
      }
      if (Object.keys(data.member_only_information).length === 0) {
        delete data.member_only_information;
      }

      await dispatch(createOrUpdateMember(currentProject.id, data));

      HistoryHelper.goBack("/organization/customers", { scope: "dashboard" });
    },
  });

  useEffect(() => {
    const initializeValues = () => {
      const mappedSafeInformation = {};
      const mappedMemberOnlyInformation = {};
      for (const key in member?.safe_information) {
        const format = memberDataFields.find((item) => item.name === key);
        const value = member.safe_information[key];
        if (format.field_type === organizationMemberFields.DATE) {
          if (value) {
            mappedSafeInformation[key] = DateHelper.getDateTimeLocal(value);
          }
        } else {
          mappedSafeInformation[key] = value;
        }
      }

      for (const key in member?.member_only_information) {
        const format = memberDataFields.find((item) => item.name === key);
        const value = member.member_only_information[key];
        if (format.field_type === organizationMemberFields.DATE) {
          if (value) {
            mappedMemberOnlyInformation[key] = DateHelper.getDateTimeLocal(value);
          }
        } else {
          mappedMemberOnlyInformation[key] = value;
        }
      }

      formik.setValues({
        ...member,
        safe_information: mappedSafeInformation,
        member_only_information: mappedMemberOnlyInformation,
      });
    };
    initializeValues();
  }, [member, memberDataFields, formik]);

  const handleClickCancel = () => {
    HistoryHelper.goBack("/organization/customers", { scope: "dashboard" });
  };

  const parentName = [
    {
      label: t("common.customers"),
      url: "/organization/customers",
    },
    {
      label: id ? t("dashboard.customerEdit.editCustomer") : t("dashboard.customerEdit.createCustomer"),
    },
  ];

  const renderInput = (item) => {
    let component;
    let targetObjectKey = "safe_information";

    if (item.visibility_type === organizationVisibilityType.MEMBER_ONLY) {
      targetObjectKey = "member_only_information";
    }

    const handleChangeDate = (data) => {
      formik.setFieldValue(`${targetObjectKey}.${item.name}`, data);
    };

    const isInvalid =
      (formik.touched?.[targetObjectKey]?.[item.name] || formik.touched?.[targetObjectKey]) &&
      formik.errors?.[targetObjectKey]?.[item.name];

    const isDisabled = false;
    if (item.field_type === organizationMemberFields.BOOL) {
      component = (
        <PRInput
          checked={formik.values[targetObjectKey][item.name]}
          disabled={isDisabled}
          invalid={isInvalid}
          name={`${targetObjectKey}.${item.name}`}
          type="checkbox"
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
        />
      );
    } else if (item.field_type === organizationMemberFields.DATE) {
      component = (
        <PRDate
          disabled={isDisabled}
          invalid={isInvalid}
          name={`${targetObjectKey}.${item.name}`}
          value={formik.values[targetObjectKey][item.name]}
          onBlur={formik.handleBlur}
          onChange={handleChangeDate}
        />
      );
    } else if ([organizationMemberFields.STRING, organizationMemberFields.EMAIL].includes(item.field_type)) {
      component = (
        <PRInput
          disabled={isDisabled}
          invalid={isInvalid}
          name={`${targetObjectKey}.${item.name}`}
          value={formik.values[targetObjectKey][item.name]}
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
        />
      );
    } else if ([organizationMemberFields.FLOAT, organizationMemberFields.INTEGER].includes(item.field_type)) {
      const handleChangeNumber = (data) => {
        formik.setFieldValue(`${targetObjectKey}.${item.name}`, data.value);
      };
      component = (
        <PRNumber
          allowNegative={false}
          decimalScale={item.field_type === organizationMemberFields.FLOAT ? 2 : 0}
          disabled={isDisabled}
          invalid={isInvalid}
          name={`${targetObjectKey}.${item.name}`}
          value={formik.values[targetObjectKey][item.name]}
          onBlur={formik.handleBlur}
          onChange={handleChangeNumber}
        />
      );
    } else if ([organizationMemberFields.PHONE_NUMBER].includes(item.field_type)) {
      const handleChangePhoneNumber = async (data, phoneObj) => {
        formik.setFieldValue(`${targetObjectKey}.${item.name}`, phoneObj.numberValue);
        countryNameMapRef.current[item.name] = phoneObj.countryCode;
        await formik.validateField(`${targetObjectKey}.${item.name}`);
      };
      component = (!id || Object.keys(formik.values[targetObjectKey])?.length > 0) && (
        <PalPhoneNumber
          fullWidth
          reactstrapMode
          disabled={isDisabled}
          invalid={isInvalid}
          name={`${targetObjectKey}.${item.name}`}
          value={formik.values[targetObjectKey][item.name]}
          onBlur={formik.handleBlur}
          onChange={handleChangePhoneNumber}
        />
      );
    } else if (
      [organizationMemberFields.INTEGER_ARRAY, organizationMemberFields.STRING_ARRAY].includes(item.field_type)
    ) {
      const handleChangeAddItem = () => {
        const data = formik.values[targetObjectKey][item.name] || [];
        data.push("");
        formik.setFieldValue(`${targetObjectKey}.${item.name}`, data);
      };
      const handleChangeRemoveItem = (index) => () => {
        let data = formik.values[targetObjectKey][item.name];
        data = data.filter((item, i) => i !== index);
        formik.setFieldValue(`${targetObjectKey}.${item.name}`, data);
      };
      const handleChangeItem = (index) => (data) => {
        let value;
        if (organizationMemberFields.INTEGER_ARRAY === item.field_type) {
          value = data.value;
        } else {
          value = data.target.value;
        }

        let dataArr = [...(formik.values[targetObjectKey]?.[item.name] || [])];
        dataArr[index] = value;
        formik.setFieldValue(`${targetObjectKey}.${item.name}`, dataArr);
      };
      const arrayItems = formik.values[targetObjectKey][item.name] || [];
      component = (
        <Fragment>
          <Row className="g-2 justify-content-end">
            <Col xs="auto">
              <PRButton outline color="success" icon={MdAdd} onClick={handleChangeAddItem} />
            </Col>
          </Row>

          {arrayItems.map((data, index) => {
            return (
              <Row key={index} className="mt-1 gx-2 align-items-center" disabled={isDisabled}>
                <Col xs="auto">
                  <MdChevronRight />
                </Col>
                <Col xs>
                  {organizationMemberFields.INTEGER_ARRAY === item.field_type ? (
                    <PRNumber
                      borderless
                      allowNegative={false}
                      decimalScale={0}
                      name={`${targetObjectKey}.${item.name}.${index}`}
                      value={data}
                      onChange={handleChangeItem(index)}
                    />
                  ) : (
                    <PRInput
                      borderless
                      name={`${targetObjectKey}.${item.name}.${index}`}
                      value={data}
                      onChange={handleChangeItem(index)}
                    />
                  )}
                </Col>
                <Col xs="auto">
                  <PRButton outline color="danger" icon={MdDelete} onClick={handleChangeRemoveItem(index)} />
                </Col>
              </Row>
            );
          })}
          <PRFormFeedback invalid={isInvalid} />
        </Fragment>
      );
    } else if ([organizationMemberFields.USER].includes(item.field_type)) {
      const memberId = member?.[targetObjectKey]?.[item.name];
      const formikMemberId = member?.[targetObjectKey] ? formik.values[targetObjectKey][item.name] || memberId : null;
      const handleChangeSelect = (data) => {
        formik.setFieldValue(`${targetObjectKey}.${item.name}`, data);
      };
      const handleLoadOptions = debounceAsync(async (searchText, callback, signal) => {
        const memberList = await dispatch(
          getMembers(
            currentProject.id,
            {
              limit: 20,
              email__icontains: searchText,
            },
            {
              signal,
              loading: false,
            }
          )
        );
        const options = memberList.results.map((item) => {
          return {
            value: item.id,
            label: item[targetObjectKey]?.email,
          };
        });
        if (formikMemberId && !options.find((item) => item.value === formikMemberId)) {
          const member = await dispatch(
            getMember(currentProject.id, formikMemberId, {
              loading: false,
              onSuccess: () => {},
            })
          );
          if (member) {
            options.push({
              value: member.id,
              label: member[targetObjectKey]?.email,
            });
          }
        }
        return options;
      });
      component = (
        <PRSelect
          key={memberId}
          isPrimitiveValue
          lazy
          invalid={isInvalid}
          isDisabled={isDisabled}
          loadOptions={handleLoadOptions}
          value={formik.values[targetObjectKey][item.name]}
          onBlur={formik.handleBlur}
          onChange={handleChangeSelect}
        />
      );
    }
    return component;
  };
  return (
    <PRContainer name={t("common.organization")} parentName={parentName}>
      <PRPage title={t("common.customers")}>
        <Row className="g-2 align-items-center">
          {sortedMemberDataFields?.map((item) => {
            return (
              <Fragment key={item.id}>
                <Col md="4">
                  <Label className="form-Label" size="md">
                    {item.display_name || item.name}
                    {item.is_required && <span className="ms-1 text-danger">*</span>}
                  </Label>
                </Col>
                <Col md="8">{renderInput(item)}</Col>
              </Fragment>
            );
          })}
        </Row>
        <Row className="justify-content-end mt-2">
          <Col md="auto">
            <PRButton outline className="" onClick={handleClickCancel}>
              {t("common.cancel")}
            </PRButton>
            <PRButton className="ms-2" onClick={formik.handleSubmit}>
              {id ? t("common.update") : t("common.create")}
            </PRButton>
          </Col>
        </Row>
      </PRPage>
    </PRContainer>
  );
}
