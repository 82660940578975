import * as Sentry from "@sentry/react";

import {
  apiUrlCheckUsername,
  apiUrlCompanyRegister,
  apiUrlInvitationInfo,
  apiUrlJoinProject,
  apiUrlNewPassword,
  apiUrlRegister,
  apiUrlResetPassword,
  apiUrlSendResetPassword,
  apiUrlToken,
  apiUrlUser,
} from "~constants";
import Network from "~helpers/Network";

import * as at from "./user.types";
export const setCurrentUser = (payload) => ({
  type: at.SET_CURRENT_USER,
  payload,
});

export const setSelectedProject = (payload) => ({
  type: at.SET_SELECTED_PROJECT,
  payload,
});

export const setSelectedBot = (payload) => ({
  type: at.SET_SELECTED_BOT,
  payload,
});

// -------------- API --------------
export const getCurrentUser =
  (options = {}) =>
  async (dispatch, _getState) => {
    // const projectsPromise = Network.request(apiUrlProject.get, {
    //   ...options,
    //   loading: true,
    //   params: {
    //     limit: 9999,
    //   },
    // });
    const currentUserPromise = Network.request(apiUrlUser.get, {
      ...options,
      loading: true,
    });
    const [
      //projectsResult,
      currentUser,
    ] = await Promise.all([
      //projectsPromise,
      currentUserPromise,
    ]);

    // if (currentUser.projects?.length) {
    //   for (const p of currentUser.projects) {
    //     p.settings = projectsResult?.results.find((project) => project.id === p.id)?.settings;
    //   }
    // }
    dispatch(setCurrentUser(currentUser));
    Sentry.setUser({
      userType: "dashboard",
      id: currentUser?.id,
      firstname: currentUser?.firstname,
      username: currentUser?.username,
      email: currentUser?.email,
      preferred_2fa_method: currentUser?.preferred_2fa_method,
      role_title: currentUser?.role_title,
    });
    return currentUser;
  };

export const updateCurrentUser =
  (options = {}) =>
  (_dispatch, _getState) => {
    return Network.request(apiUrlUser.get, {
      method: "PUT",
      ...options,
      loading: true,
      successMsg: true,
    });
  };

export const updateCurrentUserPassword = (data) => (_dispatch, _getState) => {
  return Network.request(apiUrlUser.updatePassword, {
    method: "PUT",
    data,
    loading: true,
  });
};

export const update2FAStatus = (data) => (_dispatch, _getState) => {
  return Network.request(apiUrlUser.update2FAStatus, {
    method: "POST",
    data,
    loading: true,
  });
};

export const validate2FA =
  (otpValue, options = {}) =>
  (_dispatch, _getState) => {
    return Network.request(apiUrlUser.validate2FA, {
      method: "POST",
      data: {
        otp_code: otpValue,
      },
      loading: true,
      ...options,
    });
  };

export const login = (data) => (_dispatch, _getState) => {
  return Network.request(apiUrlToken.get, {
    method: "POST",
    data,
    noBearer: true,
  });
};

export const register = (data, token) => (_dispatch, _getState) => {
  return Network.request(apiUrlRegister.get, {
    method: "POST",
    data,
    noBearer: true,
    params: {
      "invitation-token": token,
    },
  });
};
export const joinProject = (token) => (_dispatch, _getState) => {
  return Network.request(apiUrlJoinProject.get, {
    method: "POST",
    noBearer: true,
    params: {
      "invitation-token": token,
    },
  });
};

export const registerCompany = (data) => (_dispatch, _getState) => {
  return Network.request(apiUrlCompanyRegister.get, {
    method: "POST",
    data,
    noBearer: true,
  });
};
export const sendResetPassword = (email, answer) => (_dispatch, _getState) => {
  const url = `${apiUrlResetPassword.get}`;

  return Network.request(url, {
    method: "POST",
    data: { email: email, answer: answer },
    noBearer: true,
  });
};

export const sendResetPasswordForUser = (username) => (_dispatch, _getState) => {
  const url = `${apiUrlSendResetPassword.get}`;

  return Network.request(url, {
    method: "POST",
    data: { username: username },
    successMsg: true,
    loading: true,
  });
};

export const resetPassword = (token, data) => (_dispatch, _getState) => {
  const url = `${apiUrlNewPassword.get}/${token}`;

  return Network.request(url, {
    method: "PUT",
    data,
    noBearer: true,
  });
};

export const getInvitationInfo = (token) => (_dispatch, _getState) => {
  return Network.request(apiUrlInvitationInfo.get, {
    noBearer: true,
    params: {
      token,
    },
  });
};
export const checkUsername =
  (token, username, options = {}) =>
  (_dispatch, _getState) => {
    return Network.request(apiUrlCheckUsername.get, {
      noBearer: true,
      noAlert: true,
      params: {
        token,
        username,
      },
      ...options,
    });
  };
