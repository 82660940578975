// import {
//   MdOutlineBarChart,
//   MdOutlineChat,
//   MdOutlineExtension,
//   MdOutlineGroup,
//   MdOutlineHeadsetMic,
//   MdOutlineHelpCenter,
//   MdOutlineHome,
//   MdOutlineSettings,
//   MdOutlineSms,
// } from "react-icons/md";

import { MdOutlineExtension } from "react-icons/md";

import { ReactComponent as ChatbotIcon } from "~assets/images/icons/sidebar-icon-chatbot.svg";
import { ReactComponent as ContactCenterIcon } from "~assets/images/icons/sidebar-icon-contact-center.svg";
import { ReactComponent as HelpDeskIcon } from "~assets/images/icons/sidebar-icon-help-desk.svg";
import { ReactComponent as HomeIcon } from "~assets/images/icons/sidebar-icon-home.svg";
import { ReactComponent as OrganizationIcon } from "~assets/images/icons/sidebar-icon-organization.svg";
import { ReactComponent as SettingsIcon } from "~assets/images/icons/sidebar-icon-settings.svg";
import { ReactComponent as StatisticsIcon } from "~assets/images/icons/sidebar-icon-statistics.svg";
import { ReactComponent as TryChatbotIcon } from "~assets/images/icons/sidebar-icon-try-chatbot.svg";
import { PrivacyPolicy, TermsOfUse } from "~components/Layout/Footer";
import { userRole } from "~constants";
import BotSettings from "~pages/ChatBot/BotSettings";
import { BotMessages } from "~pages/ChatBot/DialogComponents/bot.messages";
import Database from "~pages/ChatBot/DialogComponents/Database";
import Dictionaries from "~pages/ChatBot/DialogComponents/Dictionaries";
import EntityTypes from "~pages/ChatBot/DialogComponents/EntityTypes";
import IntentRecommend from "~pages/ChatBot/DialogComponents/IntentRecommend";
import IntentRecords from "~pages/ChatBot/DialogComponents/IntentRecords";
import IntentTags from "~pages/ChatBot/DialogComponents/IntentTags";
import Media from "~pages/ChatBot/DialogComponents/Media";
import ScenarioManager from "~pages/ChatBot/DialogComponents/ScenarioManager";
import Variables from "~pages/ChatBot/DialogComponents/Variables";
import IntentLeaderboard from "~pages/ChatBot/IntentLeaderboard";
import TryChatbot from "~pages/ChatBot/TryChatbot";
import ContactCenter from "~pages/ContactCenter";
import HelpDesk from "~pages/HelpDesk";
import Integration from "~pages/Integration";
import Microphone from "~pages/Microphone";
import Organization from "~pages/Organization";
import CustomerTicketDetail from "~pages/public/CustomerTicketDetail";
import ForgotPassword2 from "~pages/public/ForgotPassword";
import Register2 from "~pages/public/Register";
import Settings from "~pages/Settings";
import AgentStatistics from "~pages/Statistics/AgentStatistics";
import CallCenterStatistics from "~pages/Statistics/CallCenterStatistics";
import ChatbotStatistics from "~pages/Statistics/ChatbotStats2";
import SessionStatistics from "~pages/Statistics/SessionStats";
import StatisticGenerator from "~pages/Statistics/StatisticGenerator";
import TicketStatistics from "~pages/Statistics/TicketStatistics";
import Welcome from "~pages/Welcome";

import Dashboard from "../pages/Homepage/index";
import JoinProject from "../pages/public/JoinProject";
import Login2 from "../pages/public/Login";
import Logout from "../pages/public/Logout";

export const publicRoutes = [
  {
    path: "/logout",
    component: Logout,
  },
  {
    path: "/login",
    component: Login2,
  },
  {
    path: "/forgot-password",
    component: ForgotPassword2,
  },
  {
    path: "/join-project",
    component: JoinProject,
  },
  {
    path: "/ticketdetail/:alias?/",
    component: CustomerTicketDetail,
  },
  {
    path: "/register",
    component: Register2,
  },
  {
    path: "/mic",
    component: Microphone,
  },
  {
    path: "/term-of-use",
    component: function () {
      return <TermsOfUse className="m-5" />; //TODO: Translate this later with detail
    },
  },
  {
    path: "/privacy-policy",
    component: function () {
      return <PrivacyPolicy className="m-5" />; //TODO: Translate this later with detail
    },
  },
];

export const sidebarMenuData = [
  // {
  //   name: "Menu",
  //   isLabel: true,
  //   permissions: [userRole.admin],
  //   noPermission: true,
  // },

  {
    name: "layout.sidebar.welcome",
    icon: <HomeIcon className="sidebar-icon" />,
    path: "/welcome",
    component: Welcome,
    noPermission: true,
  },
  {
    name: "layout.sidebar.home",
    icon: <HomeIcon className="sidebar-icon" />,
    path: "/home",
    component: Dashboard,
    permissions: [userRole.admin],
  },
  {
    isDivider: true,
    permissions: [userRole.admin],
    noPermission: true,
  },
  // {
  //   name: "Apps",
  //   isLabel: true,
  //   permissions: [userRole.admin],
  // },
  {
    name: "layout.sidebar.tryChatbot",
    icon: <TryChatbotIcon className="sidebar-icon" />,
    // iconColor: getColor(), //"hsl(84deg 100% 62%)",
    permissions: [userRole.admin, userRole.chatbotModerator],
    path: "/try-chatbot/",
    component: TryChatbot,
  },
  {
    isDivider: true,
    permissions: [userRole.admin, userRole.chatbotModerator],
  },
  {
    name: "layout.sidebar.chatbot",
    icon: <ChatbotIcon className="sidebar-icon" />,
    // iconColor: getColor(), //"hsl(84deg 100% 62%)",
    permissions: [userRole.admin, userRole.chatbotModerator],
    path: "/chatbot/",
    children: [
      {
        name: "layout.sidebar.botSettings",
        path: "/chatbot/settings/",
        component: BotSettings,
      },
      // {
      //   name: "Try Chatbot",
      //   path: "/chatbot/try-chatbot/",
      //   component: TryChatbot,
      // },
      {
        //TODO: Learn the translation for this
        name: "layout.sidebar.intent",
        path: `/chatbot/intent/`,
        //icon: "??? mdi-book-open-variant",
        component: ScenarioManager,
      },
      {
        //TODO: Check the translation for this
        name: "layout.sidebar.entityTypes",
        path: `/chatbot/entity-types/`,
        //icon: "??? bx-customize",
        component: EntityTypes,
      },
      {
        name: "layout.sidebar.variables",
        path: `/chatbot/variables/`,
        //icon: "??? mdi-variable",
        component: Variables,
      },
      {
        name: "layout.sidebar.media",
        path: `/chatbot/media/`,
        //icon: "??? mdi-file-image",
        component: Media,
      },
      {
        name: "layout.sidebar.database",
        path: `/chatbot/database/`,
        //icon: "??? mdi-database",
        component: Database,
      },
      {
        name: "layout.sidebar.botMessages",
        path: `/chatbot/bot-messages/`,
        //icon: "??? bx-message-add",
        component: BotMessages,
      },
      {
        //TODO: Check the translation for this
        name: "layout.sidebar.intentTags",
        path: `/chatbot/intent-tags/`,
        //icon: "??? mdi-code-tags",
        component: IntentTags,
      },
      {
        name: "layout.sidebar.intentRecommendations",
        path: `/chatbot/intent-recommend/`,
        //icon: "??? mdi-code-tags",
        component: IntentRecommend,
      },
      {
        //TODO: Check the translation for this
        name: "layout.sidebar.intentRecords",
        path: `/chatbot/intent-records/`,
        //icon: "??? mdi-code-tags",
        component: IntentRecords,
      },
      {
        //TODO: Check the translation for this
        name: "layout.sidebar.dictionaries",
        path: `/chatbot/dictionaries/`,
        //icon: "??? mdi-book-alphabet",
        component: Dictionaries,
      },
      {
        name: "layout.sidebar.intentLeaderboard",
        path: `/chatbot/intent-leaderboard/`,
        //icon: "??? mdi-book-alphabet",
        component: IntentLeaderboard,
      },
    ],
  },
  {
    name: "layout.sidebar.contactCenter",
    icon: <ContactCenterIcon className="sidebar-icon" />,
    // iconColor: getColor(), //"hsl(132deg 100% 62%)",
    permissions: [userRole.admin, userRole.callCenterAgent, userRole.ticketAgent, userRole.AgentManager],
    component: ContactCenter,
    path: "/contactcenter/",
    children: [
      {
        name: "layout.sidebar.liveChat",
        path: "/contactcenter/livechat/",
        permissions: [userRole.admin, userRole.callCenterAgent, userRole.AgentManager],
      },
      {
        name: "layout.sidebar.chatHistory",
        path: "/contactcenter/history/",
      },
      {
        name: "layout.sidebar.chatMessages",
        path: "/contactcenter/chat-messages/",
      },
      {
        name: "layout.sidebar.chatLabels",
        path: "/contactcenter/chat-labels/",
      },
      {
        //TODO: Check the translation for this
        name: "layout.sidebar.wallboard",
        path: "/contactcenter/wallboard/",
        permissions: [userRole.admin, userRole.callCenterAgent, userRole.AgentManager],
      },
      {
        name: "layout.sidebar.agentLeaderboard",
        path: "/contactcenter/agent-leaderboard/",
        permissions: [userRole.admin, userRole.callCenterAgent, userRole.AgentManager],
      },
      {
        name: "layout.sidebar.agentStatistics",
        path: "/contactcenter/statistics/",
        permissions: [userRole.admin, userRole.callCenterAgent, userRole.AgentManager],
      },
    ],
  },
  {
    name: "layout.sidebar.statistics",
    icon: <StatisticsIcon className="sidebar-icon" />,
    // iconColor: getColor(), //"hsl(300deg 100% 62%)",
    permissions: userRole.admin,
    path: "/stats/",
    children: [
      {
        name: "layout.sidebar.chatbotStatistics",
        path: "/stats/chatbot/",
        component: ChatbotStatistics,
      },
      {
        name: "layout.sidebar.callCenterStatistics",
        path: "/stats/callcenter/",
        component: CallCenterStatistics,
      },
      {
        name: "layout.sidebar.ticketStatistics",
        path: "/stats/ticket/",
        component: TicketStatistics,
      },
      {
        name: "layout.sidebar.agentStatistics",
        path: "/stats/agent/",
        component: AgentStatistics,
      },
      // {
      //   name: "OLD Chatbot Statistics",
      //   path: "/stats/chatbot2/",
      //   component: ChatbotStats,
      // },

      // {
      //   name: "Intent Statistics",
      //   path: "/stats/intent/",
      //   component: IntentStats,
      // },
      // {
      //   name: "Help Desk Statistics",
      //   path: "/stats/helpdesk/",
      //   component: HelpDeskStats,
      // },
      // {
      //   name: "Survey Statistics",
      //   path: "/stats/survey/",
      //   component: SurveyStatistics,
      // },
      // {
      //   name: "OLD Agent Statistics",
      //   path: "/stats/agent2/",
      //   component: AgentStats,
      // },
      // {
      //   name: "Intent Leaderboard",
      //   path: "/stats/intent-leaderboard/",
      //   component: IntentLeaderboard,
      //   componentProps: { scope: "statistics" },
      // },
      {
        name: "layout.sidebar.sessionStatistics",
        path: "/stats/session/",
        component: SessionStatistics,
      },
      {
        name: "layout.sidebar.statisticGenerator",
        path: "/stats/statistic-generator/",
        component: StatisticGenerator,
      },
      // {
      //   name: "Ticket Statistics",
      //   path: "/stats/ticket/",
      //   component: TicketStats,
      // },
    ],
  },
  {
    name: "layout.sidebar.helpDesk",
    icon: <HelpDeskIcon className="sidebar-icon" />,
    // iconColor: getColor(), //"hsl(174deg 100% 62%)",
    permissions: [userRole.admin, userRole.ticketAgent],
    path: "/helpdesk/",
    component: HelpDesk,
    children: [
      {
        name: "layout.sidebar.ticket",
        path: "/helpdesk/ticket/",
      },
      {
        name: "layout.sidebar.ticketCategory",
        path: "/helpdesk/ticket-category/",
      },
    ],
  },
  {
    name: "layout.sidebar.organization",
    icon: <OrganizationIcon className="sidebar-icon" />,
    // iconColor: getColor(), //"hsl(216deg 100% 62%)",

    path: "/organization/",
    component: Organization,
    permissions: [userRole.admin, userRole.organizationManager, userRole.ResourceManager],
    children: [
      {
        name: "layout.sidebar.customerDataFormat",
        path: "/organization/customer-data-format/",
        permissions: [userRole.admin, userRole.organizationManager],
      },
      {
        name: "layout.sidebar.customers",
        path: "/organization/customers/",
        permissions: [userRole.admin, userRole.organizationManager],
      },
      {
        name: "layout.sidebar.reservations",
        path: "/organization/reservations/",
        permissions: [userRole.admin, userRole.organizationManager, userRole.ResourceManager],
      },
      // {
      //   name: "Reservation Generator",
      //   path: "/organization/reservation-generator/",
      //   permissions: [userRole.admin, userRole.organizationManager],
      // },
      {
        name: "layout.sidebar.announcement",
        path: "/organization/announcement/",
        permissions: [userRole.admin, userRole.organizationManager],
      },
      {
        name: "layout.sidebar.surveys",
        path: "/organization/surveys/",
        permissions: [userRole.admin, userRole.organizationManager],
      },
      {
        name: "layout.sidebar.filterTemplates",
        path: "/organization/filter-templates/",
        permissions: [userRole.admin, userRole.organizationManager],
      },
      {
        name: "layout.sidebar.actionTemplates",
        path: "/organization/action-templates/",
        permissions: [userRole.admin, userRole.organizationManager],
      },
    ],
  },
  process.env.BUILD_ENV === "development" && {
    name: "layout.sidebar.integration",
    icon: <MdOutlineExtension className="fs-3 className=" />,
    // iconColor: getColor(), //"hsl(258deg 100% 62%)",
    permissions: userRole.admin,
    path: "/integration/",
    component: Integration,
    children: [
      {
        name: "layout.sidebar.module",
        path: "/integration/module/",
      },
      {
        name: "layout.sidebar.moduleIntegration",
        path: "/integration/module-integration/",
      },
    ],
  },
  // {
  //   name: "Projects",
  //   isLabel: true,
  //   permissions: userRole.admin,
  // },
  {
    name: "layout.sidebar.settings",
    icon: <SettingsIcon className="sidebar-icon" />,
    // iconColor: getColor(), //"hsl(342deg 100% 62%)",
    permissions: userRole.admin,
    path: "/settings/",
    component: Settings,
    position: "bottom",
    children: [
      {
        name: "layout.sidebar.channels",
        path: "/settings/channels/",
      },
      {
        name: "layout.sidebar.project",
        path: "/settings/project/",
      },
      {
        name: "layout.sidebar.popup",
        path: "/settings/popup/",
      },
      {
        name: "layout.sidebar.user",
        path: "/settings/user/",
      },
      {
        name: "layout.sidebar.templates",
        path: "/settings/message-template/",
      },
      {
        name: "layout.sidebar.assets",
        path: "/settings/assets/",
      },
      {
        name: "layout.sidebar.systemTemplates",
        path: "/settings/system-message-template/",
        onlySuperuser: true,
      },
      {
        name: "layout.sidebar.guideDefinition",
        path: "/settings/guide-definition/",
        onlySuperuser: true,
      },
    ].filter(Boolean),
  },
].filter(Boolean);

export const authProtectedRoutes = [];

//traverse nested and push authProtectedRoutes
function getRoutesFromMenu(
  menuItem,
  parentItem,
  {
    parentOrCurrentPermission: parentOrCurrentPermissionProp,
    parentOrCurrentOnlySuperuser: parentOrCurrentOnlySuperuserProp,
    parentOrCurrentNoPermission: parentOrCurrentNoPermissionProp,
  } = {}
) {
  const children = menuItem?.children || [];

  const parentOrCurrentPermission = menuItem?.permissions || parentOrCurrentPermissionProp;
  const parentOrCurrentOnlySuperuser = menuItem?.onlySuperuser || parentOrCurrentOnlySuperuserProp;
  const parentOrCurrentNoPermission = menuItem?.noPermission || parentOrCurrentNoPermissionProp;

  const activeComponent = menuItem?.component || parentItem?.component;

  if (activeComponent && menuItem?.path) {
    authProtectedRoutes.push({
      name: menuItem.name,
      path: menuItem.path,
      component: activeComponent,
      componentProps: menuItem.componentProps,
      permissions: parentOrCurrentPermission,
      onlySuperuser: parentOrCurrentOnlySuperuser,
      noPermission: parentOrCurrentNoPermission,
    });
  }
  if (children?.length) {
    for (const child of children) {
      getRoutesFromMenu(child, menuItem, {
        parentOrCurrentPermission,
        parentOrCurrentOnlySuperuser,
        parentOrCurrentNoPermission,
      });
    }
    authProtectedRoutes.push({
      name: menuItem.name,
      redirect: true,
      path: menuItem.path,
      to: children[0].path,
      permissions: parentOrCurrentPermission,
      onlySuperuser: parentOrCurrentOnlySuperuser,
      noPermission: parentOrCurrentNoPermission,
    });
  }
}

function loadRoutes() {
  for (const menuItem of sidebarMenuData) {
    getRoutesFromMenu(menuItem);
  }
}
loadRoutes();
