import { useEffect, useMemo, useState } from "react";

import { withCardon } from "cardon";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { Grid, Typography } from "@mui/material";

import useLoading from "~common/hooks/useLoading";
import PRModal from "~components/Generic/PRModal";
import { organizationMemberFields, organizationVisibilityType } from "~constants";
import { CustomerListCellRenderer } from "~pages/Organization/Customer/CustomerList";
import { getMemberFieldFormatList } from "~store/organization/actions";
import { selectMemberFieldFormat } from "~store/organization/selectors";
import { getPopupSettings } from "~store/settings/popupSettings/actions";

function LivechatPersonalDetailModalContent({ get, dataProject, dataObj }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [loading, q] = useLoading();
  const [popupSettings, setPopupSettings] = useState([]);
  const welcomeFormItems = useMemo(() => {
    return popupSettings?.welcome_form?.form_items || [];
  }, [popupSettings]);

  const memberFieldFormat = useSelector(selectMemberFieldFormat);
  const memberDataFields = memberFieldFormat?.member_data_fields;

  useEffect(() => {
    if (!dataProject) return;
    q(dispatch(getMemberFieldFormatList(dataProject)));
    q(
      dispatch(
        getPopupSettings(dataProject, {
          skipUpdate: true,
        })
      )
    ).then((res) => {
      setPopupSettings(res);
    });
  }, [dispatch, dataProject, q]);

  const memberDataFieldsWithWelcomeForm = useMemo(() => {
    const fields = [...(memberDataFields || [])];
    welcomeFormItems.forEach((item) => {
      const field = fields.find((field) => field.name === item.form_key);
      const fieldIndex = fields.findIndex((f) => f === field);

      const welcomeFormObj = {
        name: item.form_key,
        display_name: item.form_label,
        field_type:
          item.form_item_type?.toLowerCase() === "email" //formItemTypeOptions
            ? organizationMemberFields.EMAIL
            : item.form_item_type?.toLowerCase() === "phone number" //formItemTypeOptions
            ? organizationMemberFields.PHONE_NUMBER
            : organizationMemberFields.STRING,
        is_required: true,
        identifier: item.is_identifier,
        visibility_type: organizationVisibilityType.PUBLIC,

        readonly: true,
      };
      if (!field) {
        fields.unshift(welcomeFormObj);
      } else {
        const newField = { ...field, ...welcomeFormObj };
        fields[fieldIndex] = newField;
        //move to top
        fields.splice(fieldIndex, 1);
        fields.unshift(newField);
      }
    });
    return fields;
  }, [memberDataFields, welcomeFormItems]);

  const fields = useMemo(() => {
    if (!memberDataFieldsWithWelcomeForm) return [];
    const sortedFields = [...memberDataFieldsWithWelcomeForm]?.sort((a, b) => a.order - b.order);
    const list = [];
    sortedFields?.forEach((field) => {
      if (dataObj[field.name] === undefined) return;
      list.push({
        label: field.display_name || field.name,
        value: CustomerListCellRenderer({
          cellKey: field.name,
          memberDataFields: memberDataFieldsWithWelcomeForm,
          value: dataObj[field.name],
        }),
      });
    });
    return list;
  }, [dataObj, memberDataFieldsWithWelcomeForm]);

  return (
    <PRModal
      loading={loading}
      submitText=""
      title={t("component.liveChatPersonalDetailModal.title")}
      onClose={get(false)}
    >
      <Grid container spacing={2}>
        {fields.map((field) => (
          <Grid key={field.label} item xs={12}>
            <Typography fontWeight={"bold"}>{field.label}</Typography>
            <Typography fontWeight={"light"}>{field.value}</Typography>
          </Grid>
        ))}
      </Grid>
    </PRModal>
  );
}
const LivechatPersonalDetailModal = withCardon(LivechatPersonalDetailModalContent, { destroyOnHide: true });
export default LivechatPersonalDetailModal;
