class AvgPool {
  constructor(capacity) {
    this.capacity = capacity;
    this.values = [];
  }

  put(value) {
    if (this.values.length >= this.capacity) {
      this.values.shift();
    }
    this.values.push(value);
    return this.calculateWeightedAverage();
  }

  calculateWeightedAverage() {
    let totalWeight = 0;
    let weightedSum = 0;

    this.values.forEach((value) => {
      const weight = this.getWeight(value);
      totalWeight += weight;
      weightedSum += value * weight;
    });

    return weightedSum / totalWeight;
  }

  getWeight(value) {
    return Math.log(value + 1); // +1 for preventing log(0)
  }
}

class Scaler {
  constructor(minScale, maxScale, fixedScale) {
    this.minScale = minScale;
    this.maxScale = maxScale;
    this.minValue = fixedScale?.min ?? Infinity;
    this.maxValue = fixedScale?.max ?? -Infinity;
    this.fixedScale = fixedScale;
  }

  put(value) {
    // Min and max extend automatically if fixedScale is not set
    if (!this.fixedScale?.min && value < this.minValue) {
      this.minValue = value;
    }
    if (!this.fixedScale?.max && value > this.maxValue) {
      this.maxValue = value;
    }

    if (this.minValue === this.maxValue) {
      return null;
    }

    return this.scaleValue(value);
  }

  scaleValue(value) {
    const scaled =
      ((value - this.minValue) / (this.maxValue - this.minValue)) * (this.maxScale - this.minScale) + this.minScale;
    return scaled;
  }
}

export default class AudioVisualizerHelper {
  constructor({ onVolumeChanged } = {}) {
    this.audioContext = new (window.AudioContext || window.webkitAudioContext)();
    this.onVolumeChanged = onVolumeChanged;
    this.initPromise = this.initAudio();
    this.microphone = null;
    this.processorNode = null;
  }

  async initAudio() {
    await this.audioContext.audioWorklet.addModule(`${window.pr_config.baseUrl}/audio.js`);
    this.processorNode = new AudioWorkletNode(this.audioContext, "audio-visualizer-processor");
    const avgPool = new AvgPool(100);
    const scaler = new Scaler(0, 100, { min: 0, max: 0.1 });
    this.processorNode.port.onmessage = (event) => {
      const rawVolume = event.data;
      const pooledVolume = avgPool.put(rawVolume);
      const scaledVolume = scaler.put(pooledVolume);
      this.onVolumeChanged && this.onVolumeChanged(scaledVolume || 0);
    };
  }

  async initMicrophone(stream) {
    this.microphone = this.audioContext.createMediaStreamSource(stream);
    this.microphone.connect(this.processorNode);
    this.processorNode.connect(this.audioContext.destination);
  }

  async start({ onVolumeChanged }) {
    this.onVolumeChanged = onVolumeChanged;
    // navigator.mediaDevices ve getUserMedia desteğini kontrol et
    if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
      console.error("getUserMedia is not supported in this browser.");
      return;
    }
    try {
      await this.initPromise;
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      this.initMicrophone(stream);
    } catch (err) {
      console.error("Error capturing audio:", err);
    }
  }

  stop() {
    if (this.microphone) {
      this.microphone.disconnect();
      this.processorNode.disconnect();
    }
  }

  dispose() {
    this.stop();
    if (this.audioContext) {
      this.audioContext.close();
    }
    this.audioContext = null;
    this.microphone = null;
    this.processorNode = null;
  }
}
