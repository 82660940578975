import { safeProduce as produce } from "~helpers/immer";

import * as at from "./actionTypes";

/**
 * @typedef {{
 *   type: "PHONE_NUMBER" | "URL" | "QUICK_REPLY";
 *   text: string;
 *   phone_number?: string;
 *   url?: string;
 *   example?: string[];
 * }} Button
 *
 * @typedef {{
 *   id: number | null;
 *   template_id: string;
 *   data: {
 *     footer: string;
 *     buttons: Button[];
 *     content: string;
 *     example: string;
 *     category: string;
 *     vertical: string;
 *     elementName: string;
 *     enableSample: boolean;
 *     languageCode: string;
 *     templateType: string;
 *     addSecurityRecommendation: boolean;
 *     allowTemplateCategoryChange: boolean;
 *   };
 *   response_data: {
 *     id: string;
 *     data: string;
 *     meta: string;
 *     appId: string;
 *     retry: number;
 *     stage: string;
 *     status: string;
 *     wabaId: string;
 *     quality: string;
 *     category: string;
 *     priority: number;
 *     vertical: string;
 *     createdOn: number;
 *     namespace: string;
 *     modifiedOn: number;
 *     elementName: string;
 *     languageCode: string;
 *     templateType: string;
 *     containerMeta: string;
 *     languagePolicy: string;
 *   };
 *   media_file: null | any;
 *   template_type: string;
 *   project: number;
 * }} WhatsappTemplate
 */

export const initState = {
  /** @type {WhatsappTemplate[]} */
  whatsappTemplateList: [],
  /** @type {WhatsappTemplate} */
  whatsappTemplate: null,
};

const platform = (state = initState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case at.SET_WHATSAPP_TEMPLATE_LIST:
        draft.whatsappTemplateList = action.payload;
        break;

      case at.SET_WHATSAPP_TEMPLATE:
        draft.whatsappTemplate = action.payload;
        break;
    }
  });

export default platform;
