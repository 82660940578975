import { useCallback, useEffect, useMemo } from "react";

import { useTranslation } from "react-i18next";
import {
  MdAdd,
  MdDelete,
  MdDownload,
  MdInfo,
  MdKey,
  MdKeyboardArrowDown,
  MdKeyboardArrowUp,
  MdUpload,
} from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { ButtonGroup, Col, Row } from "reactstrap";

import PRAlert from "~components/Generic/PRAlert";
import PRButton from "~components/Generic/PRButton";
import PRContainer from "~components/Generic/PRContainer";
import PRInput from "~components/Generic/PRInput";
import PRSelect from "~components/Generic/PRSelect";
import PRTable from "~components/Generic/PRTable";
import PRTooltip from "~components/Generic/PRTooltip";
import {
  organizationMemberFields,
  organizationMemberFieldsOptions,
  organizationVisibilityType,
  organizationVisibilityTypeOptions,
} from "~constants";
import DialogHelper from "~helpers/DialogHelper";
import Utils from "~helpers/Utils";
import { getMemberFieldFormatList, setMemberFieldFormat, updateMemberDataFormat } from "~store/organization/actions";
import { selectMemberFieldFormat } from "~store/organization/selectors";
import { selectPopupSettingsState } from "~store/settings/popupSettings/selectors";
import { selectCurrentProject } from "~store/user/selectors";

import "./style.scss";

export default function MemberFormat() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const memberFieldFormat = useSelector(selectMemberFieldFormat);
  const memberDataFields = memberFieldFormat.member_data_fields;
  const currentProject = useSelector(selectCurrentProject);
  const popupSettings = useSelector(selectPopupSettingsState);

  const welcomeFormItems = useMemo(() => {
    return popupSettings?.welcome_form?.form_items || [];
  }, [popupSettings]);

  const memberDataFieldsWithWelcomeForm = useMemo(() => {
    const fields = [...(memberDataFields || [])];
    welcomeFormItems.forEach((item) => {
      const field = fields.find((field) => field.name === item.form_key);
      const fieldIndex = fields.findIndex((f) => f === field);

      const welcomeFormObj = {
        name: item.form_key,
        display_name: item.form_label,
        field_type:
          item.form_item_type?.toLowerCase() === "email" //formItemTypeOptions
            ? organizationMemberFields.EMAIL
            : item.form_item_type?.toLowerCase() === "phone number" //formItemTypeOptions
            ? organizationMemberFields.PHONE_NUMBER
            : organizationMemberFields.STRING,
        is_required: ["full_name", "email"].includes(item.form_key) ? true : field?.is_required,
        identifier: field?.identifier,
        visibility_type: organizationVisibilityType.PUBLIC,

        readonly: true,
        isPrimaryField: ["full_name", "email"].includes(item.form_key),
      };
      if (!field) {
        fields.unshift(welcomeFormObj);
      } else {
        const newField = { ...field, ...welcomeFormObj };
        fields[fieldIndex] = newField;
        //move to top
        fields.splice(fieldIndex, 1);
        fields.unshift(newField);
      }
    });
    fields.sort((a, b) => a.order - b.order);
    return fields;
  }, [memberDataFields, welcomeFormItems]);

  useEffect(() => {
    if (!memberFieldFormat?.member_data_fields?.length && welcomeFormItems?.length) {
      let prevData = { ...memberFieldFormat };

      dispatch(setMemberFieldFormat({ ...prevData, member_data_fields: [...memberDataFieldsWithWelcomeForm] }));
    }
  }, [dispatch, memberDataFieldsWithWelcomeForm, memberFieldFormat, welcomeFormItems]);

  const handleCreateNew = useCallback(() => {
    dispatch(
      setMemberFieldFormat({
        ...memberFieldFormat,
        member_data_fields: [
          ...memberDataFieldsWithWelcomeForm,
          {
            name: "",
            display_name: "",
            member_data_format: "",
            field_type: organizationMemberFields.STRING,
            is_required: false,
            is_manager: false,
            identifier: false,
            visibility_type: organizationVisibilityType.PUBLIC,
          },
        ],
      })
    );
  }, [dispatch, memberDataFieldsWithWelcomeForm, memberFieldFormat]);

  const handleClickSave = () => {
    const errors = [];

    if (!memberDataFieldsWithWelcomeForm.find((item) => item.identifier === true)) {
      errors.push(t("dashboard.customerDataFormat.errors.identifier"));
    }
    if (memberDataFieldsWithWelcomeForm.find((item) => !item.name)) {
      errors.push(t("dashboard.customerDataFormat.errors.name"));
    }
    if (memberDataFieldsWithWelcomeForm.find((item) => !item.display_name)) {
      errors.push(t("dashboard.customerDataFormat.errors.displayName"));
    }
    if (errors.length) {
      DialogHelper.showValidate(errors);
      return;
    }

    dispatch(
      updateMemberDataFormat({
        ...memberFieldFormat,
        member_data_fields: memberDataFieldsWithWelcomeForm.map((item, index) => ({ ...item, order: index + 1 })),
      })
    );
  };

  useEffect(() => {
    if (!currentProject.id) return;

    dispatch(getMemberFieldFormatList(currentProject.id));
  }, [dispatch, currentProject.id]);

  const columns = useMemo(() => {
    const handleChange = (row, key) => (e) => {
      //check if e is coming from checkbox
      let value = e.target ? (e.target.type === "checkbox" ? e.target.checked : e.target.value) : e;

      if (key === "visibility_type" || key === "field_type") {
        value = e.value;
      }

      let prevData = { ...memberFieldFormat };
      let prevMemberDataFields = [...memberDataFieldsWithWelcomeForm];
      prevMemberDataFields = prevMemberDataFields.map((item) => {
        if (key === "identifier") {
          if (item === row) {
            return {
              ...item,
              ...(value
                ? {
                    is_required: row.isPrimaryField ? true : item.is_required,
                    visibility_type: organizationVisibilityType.PUBLIC,
                  }
                : {}),
              [key]: value,
            };
          }
          return { ...item, identifier: false };
        }
        if (item === row) {
          return {
            ...item,
            [key]: value,
          };
        }
        return item;
      });
      dispatch(setMemberFieldFormat({ ...prevData, member_data_fields: prevMemberDataFields }));
    };

    const handleChangeMove = (index, direction) => () => {
      const targetIndex = direction === "up" ? index - 1 : index + 1;

      let prevData = { ...memberFieldFormat };
      let prevMemberDataFields = [...memberDataFieldsWithWelcomeForm];

      if (targetIndex < 0 || targetIndex >= prevMemberDataFields.length) return prevMemberDataFields;
      let sortedData = Utils.arrayMove([...prevMemberDataFields], index, targetIndex);
      sortedData = sortedData.map((item, index) => ({ ...item, order: index + 1 }));

      dispatch(setMemberFieldFormat({ ...prevData, member_data_fields: sortedData }));
    };

    const handleDelete = (row) => async () => {
      const isDirectDelete = !row.id && !row.display_name && !row.name && !row.member_data_format;
      if (!isDirectDelete && !(await DialogHelper.showQuestionDelete())) return;

      let prevData = { ...memberFieldFormat };
      let prevMemberDataFields = [...memberDataFieldsWithWelcomeForm];

      dispatch(
        setMemberFieldFormat({ ...prevData, member_data_fields: prevMemberDataFields?.filter((item) => item !== row) })
      );
    };
    return [
      {
        label: t("common.name"),
        key: "name",
        className: "pr-member-format-table-td",
        render: (row) => {
          if (row.readonly) {
            return (
              <div className="d-flex align-items-center">
                <PRInput
                  className="me-1 w-100"
                  defaultValue={row.name}
                  disabled={row.readonly}
                  onChange={handleChange(row, "name")}
                />
                <PRTooltip title={t("dashboard.customerDataFormat.readonlyTooltip")}>
                  <span>
                    <MdInfo />
                  </span>
                </PRTooltip>
              </div>
            );
          }
          return <PRInput defaultValue={row.name} disabled={row.readonly} onChange={handleChange(row, "name")} />;
        },
      },
      {
        label: t("common.displayName"),
        key: "display_name",
        className: "pr-member-format-table-td",
        render: (row) => (
          <PRInput
            defaultValue={row.display_name}
            disabled={row.readonly}
            onChange={handleChange(row, "display_name")}
          />
        ),
      },
      {
        label: t("dashboard.customerDataFormat.fieldType"),
        key: "field_type",
        className: "pr-member-format-table-td",
        style: { minWidth: "160px" },
        render: (row) => (
          <PRSelect
            menuPortal
            defaultValue={organizationMemberFieldsOptions[0]}
            isClearable={false}
            isDisabled={row.readonly}
            options={organizationMemberFieldsOptions}
            value={organizationMemberFieldsOptions.find((item) => item.value === row.field_type)}
            onChange={handleChange(row, "field_type")}
          />
        ),
      },
      {
        label: t("dashboard.customerDataFormat.visibilityType"),
        key: "visibility_type",
        className: "pr-member-format-table-td",
        render: (row) => (
          <PRSelect
            menuPortal
            defaultValue={organizationVisibilityTypeOptions[0]}
            isClearable={false}
            isDisabled={row.identifier || row.readonly}
            options={organizationVisibilityTypeOptions}
            value={organizationVisibilityTypeOptions.find((item) => item.value === row.visibility_type)}
            onChange={handleChange(row, "visibility_type")}
          />
        ),
      },
      // {
      //   label: "Format",
      //   key: "member_data_format",
      //   className: "pr-member-format-table-td",
      //   render: (row) => <PRInput value={row.member_data_format} onChange={handleChange(row, "member_data_format")} />,
      // },
      {
        label: t("dashboard.customerDataFormat.isRequired"),
        key: "is_required",
        className: "text-center",
        render: (row) => (
          <PRInput
            checked={row.is_required}
            disabled={row.isPrimaryField || row.identifier}
            type="checkbox"
            onChange={handleChange(row, "is_required")}
          />
        ),
      },
      {
        label: t("dashboard.customerDataFormat.isManager"),
        key: "is_manager",
        className: "text-center",
        render: (row) => (
          <PRInput checked={row.is_manager} type="checkbox" onChange={handleChange(row, "is_manager")} />
        ),
      },
      {
        label: t("dashboard.customerDataFormat.isIdentifier"),
        key: "identifier",
        className: "text-center",
        render: (row) => (
          <PRInput
            checked={row.identifier}
            // disabled={row.readonly}
            type="checkbox"
            onChange={handleChange(row, "identifier")}
          />
        ),
      },
      {
        fixed: "right",
        label: t("common.actions"),
        key: "actions",
        actions: true,
        className: "pr-member-format-table-td",
        render: (row, index) => (
          <div className="d-flex justify-content-center">
            <ButtonGroup>
              <PRButton
                outline
                color="primary"
                // disabled={row.readonly}
                icon={MdKeyboardArrowUp}
                size="sm"
                tooltipText={t("dashboard.customerDataFormat.moveUp")}
                onClick={handleChangeMove(index, "up")}
              />

              <PRButton
                outline
                color="primary"
                // disabled={row.readonly}
                icon={MdKeyboardArrowDown}
                size="sm"
                tooltipText={t("dashboard.customerDataFormat.moveDown")}
                onClick={handleChangeMove(index, "down")}
              />
            </ButtonGroup>
            <PRButton
              outline
              className="ms-1"
              color="danger"
              disabled={row.readonly}
              icon={MdDelete}
              size="sm"
              tooltipText={t("common.delete")}
              onClick={handleDelete(row)}
            />
          </div>
        ),
      },
    ];
  }, [dispatch, memberDataFieldsWithWelcomeForm, memberFieldFormat, t]);

  const actions = useMemo(() => {
    return [
      {
        label: t("dashboard.customerDataFormat.addNew"),
        icon: MdAdd,
        color: "success",
        onClick: handleCreateNew,
      },
    ];
  }, [handleCreateNew, t]);

  const serverSavedMemberDataFieldsWithWelcomeForm = useMemo(() => {
    return memberDataFieldsWithWelcomeForm.filter((item) => item.id);
  }, [memberDataFieldsWithWelcomeForm]);
  return (
    <PRContainer
      actions={actions}
      description={t("dashboard.customerDataFormat.description")}
      name={t("common.organization")}
      parentName={t("dashboard.customerDataFormat")}
    >
      {!serverSavedMemberDataFieldsWithWelcomeForm?.length && (
        <PRAlert color="warning">
          {t("dashboard.customerDataFormat.notInitializedWarning1")}{" "}
          <span className="fw-bold">{t("dashboard.customerDataFormat.notInitializedWarning2")}</span>{" "}
          {t("dashboard.customerDataFormat.notInitializedWarning3")}{" "}
          <span className="fw-bold">{t("dashboard.customerDataFormat.notInitializedWarning4")}</span>{" "}
          {t("dashboard.customerDataFormat.notInitializedWarning5")}
        </PRAlert>
      )}
      <PRTable noPagination columns={columns} data={memberDataFieldsWithWelcomeForm} />
      <Row className="mt-2 justify-content-end">
        <Col md={"auto"}>
          <PRButton onClick={handleClickSave}>{t("common.save")}</PRButton>
        </Col>
      </Row>
    </PRContainer>
  );
}
