import React, { useEffect } from "react";

import { Box } from "@mui/material";

import { scrollChatToBottom } from "../../ChatUtils";

export default function RecognitionMessage() {
  useEffect(() => {
    let timer = setTimeout(() => {
      scrollChatToBottom("auto");
    }, 250);
    return () => clearTimeout(timer);
  }, []);
  //This only placeholder to open vertical space for Popper
  return (
    <Box
      sx={{
        height: "40px",
      }}
    >
      &nbsp;
    </Box>
  );
}
