import i18next from "i18next";
import { cloneDeep } from "lodash";
import { all, call, put, select, takeLatest } from "redux-saga/effects";

import { createIntent, deleteIntents, getIntentDetails, updateIntent } from "~apiRequests/intent";
import { PRTextAreaFormat } from "~components/Generic/PRInput";
import { dialogComponentsIntentType, dialogComponentsResponseMessageTypes } from "~constants";
import AlertHelper from "~helpers/AlertHelper";
import DialogHelper from "~helpers/DialogHelper";
import HistoryHelper from "~helpers/HistoryHelper";
import SanitizeHelper from "~helpers/SanitizeHelper";
import Utils from "~helpers/Utils";
import store from "~store";
import { selectCurrentBot, selectCurrentProject, selectUserInfo } from "~store/user/selectors";

import { setScenarioFormState, setScenarioLoading, setScenarioSaving } from "./actions";
import { CREATE_OR_UPDATE_SCENARIO_MANAGER, FETCH_SCENARIO_FORM } from "./actionTypes";
import { initState } from "./reducer";
import { selectScenarioManager, selectScenarioManagerIntentType } from "./selectors";
import { t } from "i18next";

/**
 * @param {typeof import("./reducer").initState.intent.response.message_items} messageItemList - Message item
 * @returns {string[]} Error list
 */
function nestedResponseValidate(messageItemList) {
  const { t } = i18next;
  const errorList = [];
  for (let i = 0; i < messageItemList.length; i++) {
    const messageItem = messageItemList[i];
    let messageItemErrors = [];
    if (messageItem.message_type === "Choice" && !messageItem.message_buttons?.length) {
      messageItemErrors.push(t("utils.nestedResponseValidate.choiceItemShouldHaveButtons"));
    } else if (messageItem.message_type === "Choice") {
      if (!messageItem.text) {
        messageItemErrors.push(t("utils.nestedResponseValidate.shouldHaveQuestion"));
      }

      for (let j = 0; j < messageItem.message_buttons.length; j++) {
        const messageButton = messageItem.message_buttons[j];
        const subValidateMessages = [];
        if (!messageButton.text) {
          messageItemErrors.push(t("utils.nestedResponseValidate.shouldHaveButtonText"));
        }
        if (messageButton.button_type === "Go Message Item") {
          subValidateMessages.push(...nestedResponseValidate([messageButton.go_message_item]));
        } else if (messageButton.button_type === "Go Intent") {
          if (!messageButton.go_intent) {
            subValidateMessages.push(t("utils.nestedResponseValidate.shouldHaveIntent"));
          }
        }
        if (subValidateMessages.length) {
          messageItemErrors.push([
            t("utils.nestedResponseValidate.shouldHaveValidData").format((j + 1).toString()),
            ...subValidateMessages,
          ]);
        }
      }
    } else if (messageItem.message_type === dialogComponentsResponseMessageTypes.file && !messageItem.file_url) {
      messageItemErrors.push(t("utils.nestedResponseValidate.shouldHaveFileUrl"));
    } else if (messageItem.message_type === dialogComponentsResponseMessageTypes.image && !messageItem.image_url) {
      messageItemErrors.push(t("utils.nestedResponseValidate.shouldHaveImageUrl"));
    } else if (messageItem.message_type === dialogComponentsResponseMessageTypes.text && !messageItem.text) {
      messageItemErrors.push(t("utils.nestedResponseValidate.shouldHaveText"));
    } else if (messageItem.message_type === dialogComponentsResponseMessageTypes.wikipediaAnswer && !messageItem.text) {
      messageItemErrors.push(t("utils.nestedResponseValidate.shouldHaveWikipediaAnswer"));
    } else if (
      messageItem.message_type === dialogComponentsResponseMessageTypes.handleReservation &&
      !messageItem.resource
    ) {
      messageItemErrors.push(t("utils.nestedResponseValidate.shouldHaveCalendar"));
    } else if (
      messageItem.message_type === dialogComponentsResponseMessageTypes.lowCode &&
      !messageItem.low_code_script
    ) {
      messageItemErrors.push(t("utils.nestedResponseValidate.shouldHaveLowCode"));
    } else if (messageItem.message_type === dialogComponentsResponseMessageTypes.goIntent && !messageItem.go_intent) {
      messageItemErrors.push(t("utils.nestedResponseValidate.shouldHaveIntent"));
    } else if (messageItem.message_type === dialogComponentsResponseMessageTypes.inline) {
      const subValidateMessages = [];
      if (!messageItem.children?.length) {
        messageItemErrors.push(t("utils.nestedResponseValidate.shouldHaveChildren"));
      } else {
        subValidateMessages.push(...nestedResponseValidate(messageItem.children));
      }

      if (subValidateMessages.length) {
        messageItemErrors.push([t("utils.nestedResponseValidate.inlineShouldHaveValidData"), ...subValidateMessages]);
      }
    }

    //Sanitize HTML for raw text
    if (
      messageItem?.message_type === dialogComponentsResponseMessageTypes.text &&
      messageItem?.data_format === PRTextAreaFormat.html &&
      messageItem?.text
    ) {
      messageItem.text = SanitizeHelper.html(messageItem.text);
    }
    if (messageItemErrors?.length) {
      errorList.push([
        t("utils.nestedResponseValidate.messageItemShouldHaveValidData").format((i + 1).toString()),
        ...messageItemErrors,
      ]);
    }
  }
  return errorList;
}

/**
 * @param {typeof import("./reducer").initState.intent.slots} slots - Message item
 * @returns {string[]} Error list
 */
function validateSlots(slots) {
  const { t } = i18next;
  const errorList = [];
  for (let i = 0; i < slots.length; i++) {
    const slot = slots[i];
    const subErrorList = [];
    if (!slot.entity_type?.id) {
      subErrorList.push(t("utils.validateSlots.entityTypeCannotBeEmpty"));
    }
    if (!slot.question) {
      subErrorList.push(t("utils.validateSlots.questionCannotBeEmpty"));
    }
    if (!slot.display_name) {
      subErrorList.push(t("utils.validateSlots.displayNameCannotBeEmpty"));
    }
    if (!slot.name) {
      subErrorList.push(t("utils.validateSlots.keyCannotBeEmpty"));
    } else {
      const isValid = Utils.isValidPythonVariableName(slot.name);
      if (!isValid.valid) {
        subErrorList.push(t("utils.validateSlots.keyIsNotValid"));
      }
    }
    if (subErrorList.length) {
      errorList.push([t("utils.validateSlots.slotShouldHaveValidData").format((i + 1).toString()), ...subErrorList]);
    }
  }
  return errorList;
}

function traverseInMessageItemsAndReorderList(messageItems) {
  //sort message items like order:1, order:2, order:3,...
  const sortedMessageItems = messageItems.sort((a, b) => a.order - b.order);
  for (let i = 0; i < sortedMessageItems.length; i++) {
    const messageItem = sortedMessageItems[i];
    messageItem.order = i + 1;

    if (messageItem.message_buttons?.length) {
      messageItem.message_buttons = traverseInMessageItemsAndReorderList(messageItem.message_buttons);
    }
    if (messageItem.children?.length) {
      messageItem.children = traverseInMessageItemsAndReorderList(messageItem.children);
    }
    if (messageItem.go_message_item) {
      messageItem.go_message_item = traverseInMessageItemsAndReorderList([messageItem.go_message_item])[0];
    }
  }
  return sortedMessageItems;
}

/**
 * @param {typeof import("./reducer").initState.intent.forms} slots - Message item
 * @returns {string[]} Error list
 */
function validateForms(slots) {
  const errorList = [];
  for (let i = 0; i < slots.length; i++) {
    const form = slots[i];
    const subErrorList = [];
    // if (!form.actions?.length) {
    //   subErrorList.push("Form actions cannot be empty");
    // } else if (form.actions.length < 1) {
    //   subErrorList.push("Form should have at least 1 action");
    // } else

    if (!form.form_items?.length) {
      subErrorList.push(t("utils.validateForms.formItemsCannotBeEmpty"));
    } else if (form.form_items.length < 1) {
      subErrorList.push(t("utils.validateForms.formShouldHaveAtLeastOneItem"));
    } else if (form.form_items.length) {
      const formItemErrorList = [];
      for (const formItem of form.form_items) {
        if (!formItem.entity_type?.id) {
          formItemErrorList.push(t("utils.validateForms.entityTypeCannotBeEmpty"));
        }
        if (!formItem.question) {
          formItemErrorList.push(t("utils.validateForms.questionCannotBeEmpty"));
        }
        if (!formItem.display_key) {
          formItemErrorList.push(t("utils.validateForms.nameCannotBeEmpty"));
        }
        if (!formItem.key) {
          formItemErrorList.push(t("utils.validateForms.keyCannotBeEmpty"));
        } else {
          const isValid = Utils.isValidPythonVariableName(formItem.key);
          if (!isValid.valid) {
            formItemErrorList.push(t("utils.validateForms.keyIsNotValid"));
          }
        }
      }
      if (formItemErrorList.length) {
        subErrorList.push([
          t("utils.validateForms.formItemShouldHaveValidData").format((i + 1).toString()),
          ...formItemErrorList,
        ]);
      }
    }

    if (form.actions.length) {
      const actionErrorList = [];
      for (const action of form.actions) {
        if (!action.action_type) {
          actionErrorList.push(t("utils.validateForms.actionTypeCannotBeEmpty"));
        } else if (action.action_type === "Save Ticket") {
          if (!action.ticket_category) {
            actionErrorList.push(t("utils.validateForms.ticketCategoryCannotBeEmpty"));
          }
          if (!action.ticket_body) {
            actionErrorList.push(t("utils.validateForms.ticketBodyCannotBeEmpty"));
          }
        } else if (action.action_type === "Send Email") {
          if (!action.email_title) {
            actionErrorList.push(t("utils.validateForms.emailTitleCannotBeEmpty"));
          }
          if (!action.email_format) {
            actionErrorList.push(t("utils.validateForms.emailBodyCannotBeEmpty"));
          }
          if (!action.email_to_sent) {
            actionErrorList.push(t("utils.validateForms.emailToCannotBeEmpty"));
          }
        } else if (action.action_type === "Low Code") {
          if (!action.low_code_script) {
            actionErrorList.push(t("utils.validateForms.lowCodeCannotBeEmpty"));
          }
        }
      }
      if (actionErrorList.length) {
        subErrorList.push([
          t("utils.validateForms.formActionShouldHaveValidData").format((i + 1).toString()),
          ...actionErrorList,
        ]);
      }
    }
    if (subErrorList.length) {
      errorList.push([t("utils.validateForms.formShouldHaveValidData").format((i + 1).toString()), ...subErrorList]);
    }
  }
  return errorList;
}

/**
 * @param {typeof import("./reducer").initState.intent} state
 * @returns {string[]} Error list
 */
function validateAndSanitizeScenarioForm(state) {
  const userInfo = selectUserInfo(store.getState()) || {};
  const intentType = selectScenarioManagerIntentType(store.getState());

  const errorList = [];
  if (!state.text) {
    errorList.push(t("utils.validateAndSanitizeScenarioForm.questionCannotBeEmpty"));
  }
  if (intentType === dialogComponentsIntentType.STANDARD) {
    if (!state.response?.message_items?.length && !state.slots?.length && !state.forms?.length) {
      errorList.push(t("utils.validateAndSanitizeScenarioForm.shouldHaveResponse"));
    }
  }

  if (!userInfo?.is_superuser && intentType === dialogComponentsIntentType.STANDARD) {
    if (state.samples?.length < 3) {
      errorList.push(t("utils.validateAndSanitizeScenarioForm.shouldHaveSamples"));
    } else if (state.samples.find((sample) => !sample?.intent_text || sample?.intent_text?.length < 2)) {
      errorList.push(t("utils.validateAndSanitizeScenarioForm.shouldHaveCharacters"));
    }
  }

  if (intentType === dialogComponentsIntentType.STANDARD) {
    if (state.slot_validation_text?.length > 0 && state.slot_validation_text?.length < 2) {
      errorList.push(t("utils.validateAndSanitizeScenarioForm.slotValidationShouldHaveCharacters"));
    }
    if (state.response?.message_items?.length) {
      errorList.push(...nestedResponseValidate(state.response.message_items));
    }
    if (state.slots?.length) {
      if (!state.slot_validation_text) {
        errorList.push(t("utils.validateAndSanitizeScenarioForm.slotValidationCannotBeEmpty"));
      } else {
        // const variables = state.slots.map((item) => messageTemplateCurlyFormat.format(item.name));
        // const notContainingVariables = [];
        // for (const varItem of variables) {
        //   if (!state.slot_validation_text.includes(varItem)) {
        //     notContainingVariables.push(varItem);
        //   }
        // }
        // if (notContainingVariables.length) {
        //   errorList.push(`Slot validation text should contain ${notContainingVariables.join(", ")} variables.`);
        // }
      }
      errorList.push(...validateSlots(state.slots));
    }
    if (state.forms?.length) {
      errorList.push(...validateForms(state.forms));
    }
  } else if (intentType === dialogComponentsIntentType.RAG) {
    // if (state.rag_fact_group?.content?.length > 50000) {
    //   errorList.push("RAG content should be less than 50000 characters");
    // }
  }

  if (errorList.length) {
    DialogHelper.showValidate(errorList);
  }

  return errorList.length === 0;
}
function* createOrUpdateIntent({ payload: isShiftPressed }) {
  const rawState = yield select(selectScenarioManager);
  const currentProject = yield select(selectCurrentProject);
  const currentBot = yield select(selectCurrentBot);
  const state = cloneDeep(rawState);
  if (!validateAndSanitizeScenarioForm(state)) return;

  yield put(setScenarioSaving(true));

  //clear redundant RAG fields
  if (state.intent_type !== dialogComponentsIntentType.RAG) {
    delete state.rag_fact_group;
  } else if (state.rag_fact_group) {
    // delete state.rag_fact_group.id;
    delete state.rag_fact_group.project;
    delete state.rag_fact_group.status;
    delete state.rag_fact_group.summary;
    delete state.rag_fact_group.related_questions;
  }
  try {
    let isIntentMovedToAnotherBot = false;
    //Reorder all orderable items
    if (state.response?.message_items?.length) {
      state.response.message_items = traverseInMessageItemsAndReorderList(state.response.message_items);
    }
    if (state.id) {
      const currentState = yield call(getIntentDetails, state.project, state.bot, state.id);
      if (JSON.stringify(currentState.data?.samples) === JSON.stringify(state?.samples) && state?.samples) {
        delete state.samples;
      }
      //compare current rag_fact_group.content with new one if it is not changed, delete it object.
      if (
        currentState?.rag_fact_group &&
        currentState?.rag_fact_group?.content === state?.rag_fact_group?.content &&
        state?.rag_fact_group?.prompt === currentState?.rag_fact_group?.prompt
      ) {
        delete state.rag_fact_group;
      }

      if (state.bot !== currentBot.id) {
        //bot changed to smalltalk or vice versa
        yield call(deleteIntents, state.project, state.bot, state.id);
        state.bot = currentBot.id;
        yield call(createIntent, state.project, state.bot, state);
        isIntentMovedToAnotherBot = true;
      } else {
        yield call(updateIntent, state.project, state.bot, state);
      }
    } else {
      state.bot = currentBot.id;
      state.project = currentProject.id;
      let response = yield call(createIntent, state.project, state.bot, state);
      if (isShiftPressed) {
        HistoryHelper.replace(`/chatbot/intent/form/${response.id}`, { scope: "dashboard" });
      }
    }

    AlertHelper.show(i18next.t("network.success"), "success");
    if (!isShiftPressed) {
      if (isIntentMovedToAnotherBot) {
        const previousUrl = HistoryHelper.getHistoryUrl("back");
        if (previousUrl) {
          const urlChunks = previousUrl.split("/");
          const botIndex = previousUrl.split("/").findIndex((x) => x === "bot");
          urlChunks[botIndex + 1] = currentBot.id;
          const newUrl = urlChunks.join("/");
          HistoryHelper.replace(newUrl);
        } else {
          HistoryHelper.goBack("/chatbot/intent", { scope: "dashboard" });
        }
      } else {
        HistoryHelper.goBack("/chatbot/intent", { scope: "dashboard" });
      }
    }
  } catch (error) {
    AlertHelper.show(i18next.t("network.serverError"), "error");
  }
  yield put(setScenarioSaving(false));
}

function* fetchScenarioForm({ payload: { id } }) {
  yield put(setScenarioLoading(true));
  const currentBot = yield select(selectCurrentBot);
  const currentProject = yield select(selectCurrentProject);

  try {
    const responseItem = yield call(getIntentDetails, currentProject.id, currentBot.id, id);
    //assign initial values;
    responseItem.response ??= { ...initState.intent.response };
    responseItem.samples?.sort((a, b) => a.intent_text.localeCompare(b.intent_text));

    yield put(setScenarioFormState(null, responseItem));
  } catch (error) {
    AlertHelper.show(i18next.t("network.serverError"), "error");
  }
  yield put(setScenarioLoading(false));
}
function* scenarioManagerSaga() {
  yield all([
    takeLatest(CREATE_OR_UPDATE_SCENARIO_MANAGER, createOrUpdateIntent),
    takeLatest(FETCH_SCENARIO_FORM, fetchScenarioForm),
  ]);
}

export default scenarioManagerSaga;
