import { omit } from "lodash";

import { apiUrlMessageTemplate } from "~constants";
import Network from "~helpers/Network";

import * as ta from "./actionTypes";

export const setMessageTemplate = (payload) => ({
  type: ta.SET_MESSAGE_TEMPLATE,
  payload,
});
export const setMessageTemplateList = (payload) => ({
  type: ta.SET_MESSAGE_TEMPLATE_LIST,
  payload,
});
export const setTemplateData = (payload) => ({
  type: ta.SET_TEMPLATE_DATA,
  payload,
});

export const setTemplateTypeList = (payload) => ({
  type: ta.SET_TEMPLATE_TYPE_LIST,
  payload,
});
export const resetMessageTemplate = () => ({
  type: ta.RESET_MESSAGE_TEMPLATE,
});

// ----------------- API -----------------

export const getMessageTemplateDefinition = (projectId, id) => () => {
  const url = `${apiUrlMessageTemplate.get.format(projectId)}${id}/`;

  return Network.request(url, {
    onSuccess: setMessageTemplate,
    loading: true,
  });
};

export const getMessageTemplateDefinitionList =
  (projectId, options = {}) =>
  () => {
    const url = apiUrlMessageTemplate.get.format(projectId);

    return Network.request(url, {
      onSuccess: ({ results }) => setMessageTemplateList(results),
      loading: true,
      ...options,
    });
  };

export const getTemplateTypeList =
  (projectId, options = {}) =>
  () => {
    const url = apiUrlMessageTemplate.getType.format(projectId);

    return Network.request(url, {
      onSuccess: setTemplateTypeList,
      loading: true,
      ...options,
    });
  };

export const createOrUpdateMessageTemplateDefinition = (projectId, data) => () => {
  const url = data.id
    ? `${apiUrlMessageTemplate.get.format(projectId)}${data.id}/`
    : apiUrlMessageTemplate.get.format(projectId);

  const method = data.id ? "PUT" : "POST";
  const dataExceptId = omit(data, "id");
  return Network.request(url, {
    method,
    data: dataExceptId,
  });
};

export const deleteMessageTemplateDefinition =
  (projectId, id, options = {}) =>
  () => {
    const url = `${apiUrlMessageTemplate.get.format(projectId)}${id}/`;

    return Network.request(url, {
      method: "DELETE",
      successMsg: true,
      loading: true,
      ...options,
    });
  };

export const uploadMessageTemplateDefinitionFile = (projectId, templateId, file) => () => {
  const url = apiUrlMessageTemplate.getFile.format(projectId, templateId);

  const formData = new FormData();
  formData.append("media_file", file);
  formData.append("is_public", true);

  return Network.request(url, {
    method: "POST",
    data: formData,
    successMsg: true,
    loading: true,
  });
};

export const getMessageTemplateDefinitionFile = (projectId, templateId, id) => () => {
  const url = `${apiUrlMessageTemplate.getFile.format(projectId, templateId)}${id}/`;

  return Network.request(url, {
    loading: true,
  });
};

export const deleteMessageTemplateDefinitionFile = (projectId, templateId, id) => () => {
  const url = `${apiUrlMessageTemplate.getFile.format(projectId, templateId)}${id}/`;

  return Network.request(url, {
    method: "DELETE",
    successMsg: true,
    loading: true,
  });
};
