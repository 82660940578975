import { safeProduce as produce } from "~helpers/immer";

import * as at from "./actionTypes";

/**
 * @typedef {{
 *   uuid: string;
 *   session: number;
 *   contact: string;
 *   text: string;
 *   resolution_message: string;
 *   status: string;
 *   project: number;
 *   assigned_user: any;
 *   opened_by: any;
 *   created: string;
 *   updated: string;
 *   comments: any[];
 *   notes: any[];
 *   share_token: string;
 *   logs: any[];
 *   files: any[];
 *   category: number;
 *   assigned_time: any;
 *   resolved_time: any;
 *   category_name: string;
 *   waiting_action: keyof typeof import("~constants/ticket").ticketWaitingAction;
 * }[]} Ticket
 */

/**
 * @typedef {{
 *   id: number;
 *   name: string;
 *   agent_list: number[];
 *   project: number;
 * }} TicketCategory
 */

/**
 * @typedef {{
 *   agent: number;
 *   assigned_ticket: number;
 *   resolved_ticket: number;
 *   average_resolved_time: number;
 * }} Agent
 *
 * @typedef {{
 *   category_name: string;
 *   category_id: number;
 *   resolved_tickets: number;
 *   created_tickets: number;
 * }} Category
 *
 * @typedef {{
 *   start: string;
 *   end: string;
 *   agents: Agent[];
 *   categories: Category[];
 *   total_assigned_ticket: number;
 *   total_resolved_ticket: number;
 *   total_average_resolution_time: number;
 * }} Range
 *
 * @typedef {{ ranges: Range[] }} TicketStats
 */

export const initState = {
  /** @type {Ticket[]} */
  ticketList: [],
  /** @type {Ticket} */
  ticket: {},
  /** @type {TicketCategory[]} */
  ticketCategoryList: [],
  /** @type {TicketCategory} */
  ticketCategory: {},
  /** @type {TicketStats} */
  ticketStats: {},
};

const helpdesk = (state = initState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case at.SET_TICKET_LIST:
        draft.ticketList = action.payload;
        break;
      case at.SET_TICKET_CATEGORY_LIST:
        draft.ticketCategoryList = action.payload;
        break;
      case at.SET_TICKET:
        draft.ticket = action.payload;
        break;
      case at.SET_TICKET_CATEGORY:
        draft.ticketCategory = action.payload;
        break;
      case at.SET_TICKET_STATS:
        draft.ticketStats = action.payload;
        break;
    }
  });

export default helpdesk;
