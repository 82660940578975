import { forwardRef, useEffect, useRef, useState } from "react";

import { withCardon } from "cardon";
import { useDispatch, useSelector } from "react-redux";

import styled from "@emotion/styled";

import useLoading from "~common/hooks/useLoading";
import PRChart from "~components/Generic/PRChart";
import PRModal from "~components/Generic/PRModal";
import AlertHelper from "~helpers/AlertHelper";
import RenderHelper from "~helpers/RenderHelper";
import SanitizeHelper from "~helpers/SanitizeHelper";
import { downloadStatisticGeneratorReport } from "~store/statisticGenerator/actions";
import { selectCurrentProject } from "~store/user/selectors";

const StyledModal = styled(PRModal)`
  .modal-body {
    max-height: 85vh;
    overflow: auto;
  }
`;

const DynamicReportContent = forwardRef(function DynamicReportContent({ reportId }, ref) {
  const currentProject = useSelector(selectCurrentProject);

  const dispatch = useDispatch();
  const [loading, q] = useLoading();

  const [reportJson, setReportJson] = useState([]);

  useEffect(() => {
    if (reportId) {
      q(dispatch(downloadStatisticGeneratorReport(currentProject.id, reportId))).then((response) => {
        if (Array.isArray(response?.data)) {
          setReportJson(response?.data || []);
        } else if (typeof response?.data === "object") {
          setReportJson([response?.data]);
        } else if (response?.data) {
          AlertHelper.showWarning("Invalid report data format. Please inspect the report's low-code");
        }
      });
    }
  }, [dispatch, reportId, currentProject?.id, q]);

  return (
    <span ref={ref} className="markdown-wrapper">
      {reportJson.map((report, index) => {
        if (report.type === "echarts") {
          const { option, style, height, ...rest } = report;
          return (
            <PRChart
              key={index}
              height={height || "500px"}
              loading={loading}
              option={option}
              {...rest}
              renderer="svg"
              style={style}
            />
          );
        } else if (report.type === "markdown") {
          const renderedMd = RenderHelper.renderMd(report.content, { html: true });
          let sanitizedHtml = SanitizeHelper.html(renderedMd);
          sanitizedHtml = RenderHelper.addBlankTargetToLinks(sanitizedHtml);

          return (
            <div
              key={index}
              className="markdown-body"
              dangerouslySetInnerHTML={{ __html: sanitizedHtml }}
              style={{ marginTop: 20, width: "100%", ...report.style }}
            />
          );
        } else if (report.type === "html") {
          let sanitizedHtml = SanitizeHelper.html(report.content);
          sanitizedHtml = RenderHelper.addBlankTargetToLinks(sanitizedHtml);
          return (
            <div
              key={index}
              dangerouslySetInnerHTML={{ __html: sanitizedHtml }}
              style={{ width: "100%", ...report.style }}
            />
          );
        }
      })}
    </span>
  );
});

function DynamicReportChartModalContent({ get, reportId }) {
  const printRef = useRef();

  const handlePrint = () => {
    const printContent = printRef.current;
    const WinPrint = window.open("", "", "left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0");

    WinPrint.document.write(printContent.innerHTML);
    // Copy <link>
    document.querySelectorAll("link[rel='stylesheet']").forEach((link) => {
      const newLink = WinPrint.document.createElement("link");
      newLink.rel = "stylesheet";
      newLink.href = link.href;
      WinPrint.document.head.appendChild(newLink);
    });

    // Copy <style>
    document.querySelectorAll("style").forEach((style) => {
      const newStyle = WinPrint.document.createElement("style");
      newStyle.textContent = style.textContent;
      WinPrint.document.head.appendChild(newStyle);
    });

    const customStyle = WinPrint.document.createElement("style");
    customStyle.textContent = `
    body, html {
      overflow: auto !important;
      height: auto !important;
      display: block !important;
      position: static !important; 
     
    }
    .echarts-for-react{
      transform: scale(0.65) !important;
      transform-origin: 0 0 !important;
    }
    .markdown-body{
      padding-right: 10px;
    }
  `;
    WinPrint.document.head.appendChild(customStyle);

    const viewportMeta = WinPrint.document.createElement("meta");
    viewportMeta.name = "viewport";
    viewportMeta.content = "width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0";
    WinPrint.document.head.appendChild(viewportMeta);

    WinPrint.document.body.classList.add("markdown-wrapper");

    WinPrint.document.close();
    WinPrint.focus();
    WinPrint.print();
  };

  return (
    <StyledModal
      centered
      isOpen={true}
      size="xl"
      submitText="Print"
      title={"Report Preview"}
      onClick={handlePrint}
      onClose={get(false)}
    >
      <DynamicReportContent ref={printRef} reportId={reportId} />
    </StyledModal>
  );
}

const DynamicReportChartModal = withCardon(DynamicReportChartModalContent, { destroyOnHide: true });

export { DynamicReportContent, DynamicReportChartModal };
export default DynamicReportChartModal;
