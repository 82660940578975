import { safeProduce as produce } from "~helpers/immer";

import * as at from "./actionTypes";

/**
 * @typedef {object} AnnouncementItem
 * @property {number} id
 * @property {string} title
 * @property {string} body
 * @property {string} start
 * @property {string} end
 * @property {string} announcement_type
 */

export const initState = {
  /** @type {AnnouncementItem[]} */
  announcementList: [],
  /** @type {number[]} */
  acknowledgedAnnouncementIds: [],
};

const announcement = (state = initState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case at.SET_ANNOUNCEMENT_LIST:
        draft.announcementList = action.payload;
        break;
      case at.READ_ANNOUNCEMENT:
        const availableIdList = draft.acknowledgedAnnouncementIds.filter((itemId) =>
          draft.announcementList.find((announcement) => announcement.id === itemId)
        );
        availableIdList.push(action.payload);
        draft.acknowledgedAnnouncementIds = availableIdList;

        break;

      case at.SET_ACKNOWLEDGED_ANNOUNCEMENT_IDS:
        draft.acknowledgedAnnouncementIds = action.payload;
        break;
    }
  });

export default announcement;
