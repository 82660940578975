import { safeProduce as produce } from "~helpers/immer";

import * as at from "./actionTypes";

/**
 * @typedef {{
 *   id: number;
 *   seen: boolean;
 *   created: string;
 *   updated: string;
 *   title: string;
 *   description: string;
 *   key: string;
 *   status: string;
 * }} NotificationBase
 *
 * @typedef {NotificationBase & {
 *   payload: {
 *     chatbot_id: number;
 *     chatbot_name: string;
 *     project_id: number;
 *   };
 *   category: "CHATBOT";
 * }} NotificationChatbot
 *
 * @typedef {NotificationBase & {
 *   payload: {
 *     user_id: number;
 *     username: string;
 *     first_name: string;
 *     last_name: string;
 *   };
 *   category: "USER";
 * }} NotificationUser
 *
 * @typedef {NotificationBase & {
 *   payload: {
 *     company_name: string;
 *     company_id: number;
 *   };
 *   category: "COMPANY";
 * }} NotificationCompany
 *
 * @typedef {NotificationBase & {
 *   payload: {
 *     project_id: number;
 *     project_name: string;
 *   };
 *   category: "PROJECT";
 * }} NotificationProject
 *
 * @typedef {NotificationChatbot | NotificationUser | NotificationCompany | NotificationProject} Notification
 */

export const initState = {
  /**
   * @type {{
   *   results: Notification[];
   *   count: number;
   * }}
   */
  notifications: {},
};

const theme = (state = initState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case at.SET_NOTIFICATIONS:
        const newNotifications = [...(draft.notifications?.results || [])];
        action.payload.results.forEach((notification) => {
          const notificationIndex = newNotifications.findIndex((n) => n.id === notification.id);
          if (notificationIndex > -1) {
            newNotifications[notificationIndex] = notification;
          } else {
            newNotifications.push(notification);
          }
        });
        draft.notifications = {
          ...action.payload,
          results: newNotifications,
        };

        // draft.notifications = action.payload;
        break;
    }
  });

export default theme;
