import React from "react";

import styled from "@emotion/styled";

import PRButton from "~components/Generic/PRButton";

const StyledDiv = styled.div`
  width: 32px;
  height: 32px;
  button {
    border: none !important;
    padding: 0;
    background-color: ${({ theme }) => theme.palette.primary.main};
  }
  svg {
    fill: ${({ theme, closeButton, disabled }) =>
      disabled
        ? closeButton
          ? theme.palette.secondary.dark
          : "#fff"
        : closeButton
        ? theme.palette.secondary.dark
        : theme.palette.primary.main};
    width: 100%;
    height: auto;
    overflow: visible;
  }
  &[disabled] {
    opacity: 1 !important;
    button {
      opacity: 1 !important;
      svg {
        opacity: 0.6;
        * {
          stroke: ${({ theme }) => theme.palette.secondary.dark} !important;
        }
      }
      background-color: #fff !important;
      /* border-color: #fff !important; */
    }
  }
`;

export default function LiveChatButton({ closeButton, ...rest }) {
  return (
    <StyledDiv closeButton={closeButton} disabled={rest.disabled}>
      <PRButton {...rest} color={closeButton ? "white" : rest.color} />
    </StyledDiv>
  );
}
