import { useEffect, useMemo } from "react";

import { useSelector } from "react-redux";

import useMonacoCompletion from "~common/hooks/useMonacoCompletion";
import { useStorage } from "~common/hooks/useStorage";
import { apiUrlLowcode, completionItemKind, dialogComponentsVariableTypeOptions } from "~constants";
import Network from "~helpers/Network";
import {
  selectScenarioManagerDatabaseList,
  selectScenarioManagerForms,
  selectScenarioManagerMediaList,
  selectScenarioManagerSlots,
  selectScenarioManagerVariableList,
} from "~store/dialogComponents/scenarioManager/selectors";
import { selectCurrentProject } from "~store/user/selectors";

export default function useMonacoScenarioEditorLowCode(editorPropBase) {
  const slots = useSelector(selectScenarioManagerSlots);
  const forms = useSelector(selectScenarioManagerForms);
  const mediaList = useSelector(selectScenarioManagerMediaList);
  const databaseList = useSelector(selectScenarioManagerDatabaseList);
  const variableList = useSelector(selectScenarioManagerVariableList);
  const currentProject = useSelector(selectCurrentProject);

  const editorProp =
    editorPropBase?.getEditorType() === "vs.editor.IDiffEditor" ? editorPropBase.getModifiedEditor() : editorPropBase;

  const [lowCodeIntelliSense, setLowCodeIntelliSense] = useStorage("pr_lowcode_intellisense_map", {});

  useEffect(() => {
    (async () => {
      const response = await Network.request(apiUrlLowcode.getDocumentation.format(currentProject.id), {
        loading: false,
      });
      const intellisenseData = [...(response || []).filter((item) => Object.keys(item).length > 0)];
      setLowCodeIntelliSense((prev) => ({ ...prev, [currentProject.id]: intellisenseData }));
    })();
  }, [currentProject.id, setLowCodeIntelliSense]);

  const editor = useMemo(() => {
    if (!editorProp) return;
    const editorLanguage = editorProp?.getModel()?.getLanguageId();
    if (editorLanguage !== "python") {
      return;
    }
    return editorProp;
  }, [editorProp]);

  const monacoIntelliSenseList = useMemo(() => {
    const activeLowCodeIntelliSense = lowCodeIntelliSense[currentProject.id] || [];
    const list = [...activeLowCodeIntelliSense];

    const mediaChildren = [];
    for (const m of mediaList) {
      mediaChildren.push({
        label: m.name,
        insertText: `["${m.name}"]`,
        detail: "Media",
        documentation: {
          value: [
            // MarkdownString
            "#### Image<br>",
            `[${m.name}](${m.media_url})<br>`,
            `<img src="${m.media_url}" width="200px" height="200px"/><br>`,
          ].join("\n"),
          supportHtml: true,
          isTrusted: true,
        },
        onHover: async () => {
          const response = await Network.request(m.media_url, {
            responseType: "blob",
            rawResponse: true,
          });
          const base64 = await new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(response.data);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
          });
          return {
            detail: "Media",
            documentation: {
              value: [
                // MarkdownString
                "#### Image<br/>",
                `[${m.name}](${m.media_url})<br/>`,
                `<img src="${base64}" width="160px" height="auto"/><br/>`,
              ].join("\n"),
            },
          };
        },
        kind: completionItemKind.Property,
      });
    }

    list.push({
      label: "media",
      insertText: "media",
      detail: "Media",
      documentation: {
        value: [
          // MarkdownString
          "#### Media<br>",
          `Media list<br>`,
        ].join("\n"),
        supportHtml: true,
        isTrusted: true,
      },
      kind: completionItemKind.Constant,
      children: mediaChildren,
    });

    const databaseChildren = [];
    for (const m of databaseList) {
      databaseChildren.push({
        label: m.name,
        insertText: `["${m.name}"]`,
        detail: "Database",
        documentation: {
          value: [
            // MarkdownString
            "#### File",
            `- [${m.name}](${m.data_url})`,
          ].join("\n"),
        },
        filterText: `\${database["${m.name}"]}`,
        kind: completionItemKind.Property,
      });
    }
    list.push({
      label: "database",
      insertText: "database",
      detail: "Database",
      documentation: {
        value: [
          // MarkdownString
          "#### Database",
          `Database list`,
        ].join("\n"),
      },
      kind: completionItemKind.Constant,
      children: databaseChildren,
    });
    for (const m of variableList) {
      const typeLabel =
        dialogComponentsVariableTypeOptions.find((item) => item.value === m.variable_type)?.label || m.variable_type;
      list.push({
        label: m.name,
        insertText: m.name,
        detail: `${m.name} = ${m.value}`,
        documentation: {
          value: [
            // MarkdownString
            "#### Variable",
            `- Type: ${typeLabel}`,
            `- Value: \`${m.value}\``,
          ].join("\n"),
        },
        filterText: `\${${m.name}}`,
        kind: completionItemKind.Variable,
      });
    }

    for (const m of slots) {
      list.push({
        label: m.name,
        insertText: m.name,
        detail: `Slot: ${m.name}`,
        documentation: {
          value: [
            // MarkdownString
            "#### Slot",
            `- Type: ${m.entity_type?.name}`,
            `- Question: ${m.question}`,
            `- Display Name: ${m.display_name}`,
            `- Key: ${m.name}`,
          ].join("\n"),
        },
        filterText: `\${${m.entity_type?.name}}\${${m.name}}\${${m.question}}\${${m.display_name}}`,
        kind: completionItemKind.Variable,
      });
    }

    let formOrder = 0;
    for (const m of forms) {
      formOrder++;
      const formItems = m?.form_items || [];
      for (const formItem of formItems) {
        list.push({
          label: formItem.key,
          insertText: formItem.key,
          detail: `Form Item(${formOrder}): ${formItem.key}`,
          documentation: {
            value: [
              // MarkdownString
              `#### Form Item${formOrder}`,
              `- Type: ${formItem.entity_type?.name}`,
              `- Question: ${formItem.question}`,
              `- Display Name: ${formItem.display_key}`,
              `- Key: ${formItem.key}`,
            ].join("\n"),
          },
          filterText: `\${${formItem.entity_type?.name}}\${${formItem.key}}\${${formItem.question}}\${${formItem.display_key}}`,
          kind: completionItemKind.Variable,
        });
      }
    }

    return list;
  }, [mediaList, databaseList, variableList, slots, forms, lowCodeIntelliSense, currentProject.id]);

  useMonacoCompletion(editor, {
    list: monacoIntelliSenseList,
  });
  return null;
}
