import React, { useEffect, useMemo, useState } from "react";

import { withCardon } from "cardon";
import { useDispatch, useSelector } from "react-redux";

import styled from "@emotion/styled";
import { ChevronLeft, ChevronRight, Close } from "@mui/icons-material";
import { Alert, Collapse, Grid } from "@mui/material";

import PRModal from "~components/Generic/PRModal";
import PalButton from "~components/mui/PalButton";
import PalIconButton from "~components/mui/PalIconButton";
import PalTooltip from "~components/mui/PalTooltip";
import { appAnnouncementAlertTypeMap } from "~constants";
import DateHelper from "~helpers/DateHelper";
import RenderHelper from "~helpers/RenderHelper";
import { getAppAnnouncementList, readAppAnnouncement } from "~store/announcement/actions";
import { selectVisibleAppAnnouncementList } from "~store/announcement/selectors";

const StyledAlert = styled(Alert)`
  .MuiAlert-message {
    width: 100%;
  }

  .MuiAlert-icon {
    display: flex;
    align-items: center;
  }
`;

const AppAnnouncementDetailModal = withCardon(
  function AppAnnouncementDetailModalContent({ get, title, body, start, end }) {
    const handleClickClose = async () => {
      get(true)();
    };
    return (
      <PRModal closeText="OK" submitText="" title={title} visible={true} onClose={handleClickClose}>
        <Grid container>
          <Grid item xs={12}>
            <div dangerouslySetInnerHTML={{ __html: RenderHelper.renderMd(body) }} />
          </Grid>
          <Grid item xs={12}>
            <div className="text-muted fs-7 mt-4">
              {DateHelper.getDateTimeLocal(start).format("LLT")} - {DateHelper.getDateTimeLocal(end).format("LLT")}
            </div>
          </Grid>
        </Grid>
      </PRModal>
    );
  },
  { destroyOnHide: true }
);

const ReadMoreButton = styled(PalButton)`
  font-size: 0.75rem;
  padding: 0rem 0.5rem;
  margin-left: 0.5rem;
`;

export default function HeaderAnnouncement() {
  const dispatch = useDispatch();

  const [currentAnnouncementIndex, setCurrentAnnouncementIndex] = useState(0);

  const visibleAppAnnouncementList = useSelector(selectVisibleAppAnnouncementList);
  const activeAnnouncementItem = useMemo(
    () => visibleAppAnnouncementList[currentAnnouncementIndex],
    [visibleAppAnnouncementList, currentAnnouncementIndex]
  );

  useEffect(() => {
    dispatch(getAppAnnouncementList());
  }, [dispatch]);

  const handleClickNext = () => {
    setCurrentAnnouncementIndex((prev) => (prev + 1) % visibleAppAnnouncementList.length);
  };

  const handleClickPrev = () => {
    setCurrentAnnouncementIndex(
      (prev) => (prev - 1 + visibleAppAnnouncementList.length) % visibleAppAnnouncementList.length
    );
  };

  const handleClickClose = () => {
    dispatch(readAppAnnouncement(activeAnnouncementItem.id));
    setCurrentAnnouncementIndex((prev) => (prev > 0 ? prev - 1 : 0));
  };

  const handleClickReadMore = async () => {
    await AppAnnouncementDetailModal.show({
      title: activeAnnouncementItem.title,
      body: activeAnnouncementItem.body,
      start: activeAnnouncementItem.start,
      end: activeAnnouncementItem.end,
    });
    handleClickClose();
  };
  return (
    <Collapse mountOnEnter unmountOnExit in={visibleAppAnnouncementList.length > 0}>
      <StyledAlert
        severity={appAnnouncementAlertTypeMap[activeAnnouncementItem?.announcement_type] ?? "info"}
        style={{ marginBottom: 0 }}
      >
        <Grid container alignItems="center" spacing={2}>
          <Grid item xs flexWrap={"nowrap"} overflow={"hidden"} textOverflow={"ellipsis"} whiteSpace={"nowrap"}>
            <PalTooltip placement="bottom" title={activeAnnouncementItem?.title}>
              {activeAnnouncementItem?.title}
            </PalTooltip>
          </Grid>
          {!!activeAnnouncementItem?.body && (
            <Grid item flexWrap={"nowrap"} xs="auto">
              <ReadMoreButton size="small" onClick={handleClickReadMore}>
                Read More
              </ReadMoreButton>
            </Grid>
          )}
          <Grid item flexWrap={"nowrap"} xs="auto">
            {visibleAppAnnouncementList?.length > 1 && (
              <>
                <span>
                  {currentAnnouncementIndex + 1} / {visibleAppAnnouncementList.length}
                </span>
                <PalIconButton size="small" onClick={handleClickPrev}>
                  <ChevronLeft />
                </PalIconButton>
                <PalIconButton size="small" onClick={handleClickNext}>
                  <ChevronRight />
                </PalIconButton>
              </>
            )}
            <PalIconButton color="error" size="small" onClick={handleClickClose}>
              <Close />
            </PalIconButton>
          </Grid>
        </Grid>
      </StyledAlert>
    </Collapse>
  );
}
