import chroma from "chroma-js";
import classNames from "classnames";
import reactStringReplace from "react-string-replace";

import styled from "@emotion/styled";
import { Tooltip } from "@mui/material";

import HtmlContent from "~components/HtmlContent";
import IFrameHelper from "~helpers/IFrameHelper";
import RenderHelper from "~helpers/RenderHelper";
import SanitizeHelper from "~helpers/SanitizeHelper";
import Utils from "~helpers/Utils";

import { domChatContainerId, domChatScrollContainerId } from "./constants";
function easeInOutQuad(t, b, c, d) {
  t /= d / 2;
  if (t < 1) return (c / 2) * t * t + b;
  t--;
  return (-c / 2) * (t * (t - 2) - 1) + b;
}
export function urlify(text = "", color) {
  let urlRegex = /(https?:\/\/[^\s]+)/g;
  let foundUrl = text?.match?.(urlRegex);
  if (foundUrl) {
    if (foundUrl.length > 0) {
      for (let url of foundUrl) {
        reactStringReplace(text, url, (match, i) => (
          <Tooltip title={url}>
            <a
              href={url}
              rel="noreferrer"
              style={{
                ...(color
                  ? {
                      color: color,
                    }
                  : {}),
              }}
              target="_blank"
            >
              <span
                style={{
                  textDecoration: "underline",
                }}
              >
                {Utils.ellipsisUrl(match)}
              </span>
            </a>
          </Tooltip>
        ));
      }

      return (
        <>
          {reactStringReplace(text, /(https?:\/\/[^\s]+)/g, (match, i) => (
            <Tooltip key={match + i} title={match}>
              <a
                href={match}
                rel="noreferrer"
                style={{
                  ...(color
                    ? {
                        color: color,
                      }
                    : {}),
                }}
                target="_blank"
              >
                <span
                  style={{
                    textDecoration: "underline",
                  }}
                >
                  {Utils.ellipsisUrl(match)}
                </span>
              </a>
            </Tooltip>
          ))}
        </>
      );
    }
  }
  return text;
}

const StyledMarkdownDiv = styled.div`
  & > div {
    width: ${(props) => (props.isEmbedded ? "calc(450px - 40px - 40px - 24px)" : "60vw")};
  }
  ${(props) => {
    if (!props.theme.palette?.chat) return "";
    const color =
      props.position === "right"
        ? props.theme.palette.chat.balloon.customer.color
        : props.sender === "AGENT"
        ? props.theme.palette.chat.balloon.agent.color
        : props.theme.palette.chat.balloon.bot.color;

    let ballonBackgroundColor =
      props.position === "right"
        ? props.theme.palette.chat.balloon.customer.backgroundColor
        : props.sender === "AGENT"
        ? props.theme.palette.chat.balloon.agent.backgroundColor
        : props.theme.palette.chat.balloon.bot.backgroundColor;

    let linkColor =
      props.position === "right"
        ? props.theme.palette.chat.balloon.customer.color
        : props.sender === "AGENT"
        ? props.theme.palette.chat.balloon.agent.color
        : props.theme.palette.chat.balloon.bot.color;

    // linkColor = chroma(linkColor).alpha(Number(props.theme.palette.chat.balloon.timeOpacity)).hex();
    const contrastDifference = chroma.contrast(ballonBackgroundColor, linkColor);

    const betterOptimalContrastRatio = 7; // WCAG
    const maximumContrastRatio = 12; // WCAG

    if (contrastDifference < betterOptimalContrastRatio) {
      const diffValue = betterOptimalContrastRatio - contrastDifference;
      linkColor = chroma(linkColor).brighten(diffValue).hex();
    } else if (contrastDifference > maximumContrastRatio) {
      const diffValue = contrastDifference - maximumContrastRatio;
      linkColor = chroma(linkColor).darken(diffValue).hex();
    }

    return ` 
      &.inner-markdown.markdown-body {
        --color-fg-default: ${color};
        --color-accent-fg: ${linkColor};
      }      
      `;
  }}
`;

function animateScroll(element, start, end, duration) {
  let startTime = null;
  return new Promise((resolve) => {
    function step(timestamp) {
      if (!startTime) startTime = timestamp;
      const elapsed = timestamp - startTime;
      const newPos = easeInOutQuad(elapsed, start, end - start, duration);
      element.scrollTop = newPos;
      window.dispatchEvent(new CustomEvent("chat-container-scrolling"));
      if (elapsed < duration) {
        requestAnimationFrame(step);
      } else {
        element.scrollTop = end;
        window.dispatchEvent(new CustomEvent("chat-container-scrolling"));
        resolve();
      }
    }
    requestAnimationFrame(step);
  });
}
/**
 * Scrolls the chat to the bottom.
 *
 * @param {"instant" | "smooth" | "auto"} mode - Scroll behavior.
 * @param {boolean} scrollToTop - Whether to scroll to the top instead of the bottom.
 */
export const scrollChatToBottom = async (mode = "auto", { skipViewingRangeCheck = false } = {}) => {
  const dom = document.getElementById(domChatContainerId);
  const chatContainerDom = dom?.parentElement;
  const scroll = document.getElementById(domChatScrollContainerId);
  const innerDom = document.getElementById(domChatContainerId);
  let hasGapAfterScroll = false;

  if (!scroll || !innerDom || !chatContainerDom) return;
  let visibleScrollNegativeOffset = 0;

  function traverseReverseAndFindUnseenDom() {
    let targetDom = null;
    let calculatedUnseenHeight = 0;
    const children = innerDom.children;

    for (let i = children.length - 1; i >= 0; i--) {
      const child = children[i];
      const subChildren = child.children;

      // Ignore if child is already out of view above
      if (child.offsetTop + child.clientHeight < scroll.scrollTop) {
        break;
      }

      for (let j = subChildren.length - 1; j >= 0; j--) {
        const subChild = subChildren[j];

        if (subChild.getAttribute("data-msg-showed") !== "true") {
          calculatedUnseenHeight += subChild.clientHeight;
        } else {
          targetDom = subChild;
          break;
        }
      }

      if (targetDom) {
        break;
      }
    }

    // Return the target DOM element and the calculated unseen height
    return { targetDom, calculatedUnseenHeight };
  }

  const extraAppendSpaceToSeePartialUnseenMsgPx = Math.max(50, scroll.clientHeight * 0.15); // 15% of the scroll height // 50px

  if (!skipViewingRangeCheck) {
    const isScrollActive = scroll.scrollHeight - scroll.clientHeight > 0;
    if (isScrollActive) {
      const { targetDom, calculatedUnseenHeight } = traverseReverseAndFindUnseenDom();

      if (targetDom) {
        const visibleArea = scroll.clientHeight - 20; //20px padding top and bottom
        const negativeValue =
          Math.max(extraAppendSpaceToSeePartialUnseenMsgPx, calculatedUnseenHeight - visibleArea) -
          extraAppendSpaceToSeePartialUnseenMsgPx;
        visibleScrollNegativeOffset = -negativeValue;
        hasGapAfterScroll = negativeValue != 0;
      }
    }
  }
  //check if last dom height is bigger than scrollable area and reduce scroll height
  const distanceFromTop = chatContainerDom.scrollTop;
  function calculateTotalHeight(element) {
    let totalHeight = 0;
    Array.from(element.children).forEach((child) => {
      totalHeight += child.offsetHeight;
    });
    return totalHeight;
  }

  const chatViewHeight = calculateTotalHeight(innerDom);
  const scrollTopTolerance = 5;

  const scrollTopLimit = visibleScrollNegativeOffset + innerDom.offsetHeight - scroll.clientHeight + 40; // 20px padding top and bottom
  // if scroll position is near top, scroll to bottom directly otherwise scroll to bottom smoothly
  const shouldSmoothScroll = distanceFromTop * 3 > chatViewHeight;
  if ((mode === "auto" && shouldSmoothScroll) || mode === "smooth") {
    if (scrollTopLimit < scroll.scrollTop + scrollTopTolerance) {
      scroll.scrollTop = scrollTopLimit + scrollTopTolerance;
    } else {
      await animateScroll(scroll, scroll.scrollTop + scrollTopTolerance, scrollTopLimit, 250);
    }
  } else if (mode !== "smooth") {
    scroll.scrollTop = visibleScrollNegativeOffset + scrollTopLimit + scrollTopTolerance;
  }

  return { hasGapAfterScroll };
};

export const getChatDom = () => {
  const dom = document.getElementById(domChatScrollContainerId);
  return dom;
};

const renderTextContent = (text, format = "plain", options = {}) => {
  if (format === "html") {
    let sanitizedHtml = SanitizeHelper.html(text);
    sanitizedHtml = RenderHelper.addBlankTargetToLinks(sanitizedHtml);
    return (
      <HtmlContent isRightBalloon={options.position === "right"} position={options.position} sender={options.sender}>
        <div dangerouslySetInnerHTML={{ __html: sanitizedHtml }} />
      </HtmlContent>
    );
  } else if (format === "markdown") {
    const renderedMd = RenderHelper.renderMd(text, { html: true });
    const sanitizedHtml = SanitizeHelper.html(renderedMd);

    const htmlDom = (
      <div
        dangerouslySetInnerHTML={{ __html: sanitizedHtml }}
        style={{
          width: "auto",
          //Extra padding to prevent scroll bar due border
          paddingTop: 1,
          paddingBottom: 1,
        }}
      />
    );
    return (
      <StyledMarkdownDiv
        className={classNames("inner-markdown markdown-body", {
          // dark: isRightBalloon,
        })}
        isEmbedded={IFrameHelper.isInIFrameOrTryChatbotOrPreview()}
        position={options.position}
        sender={options.sender}
      >
        {htmlDom}
      </StyledMarkdownDiv>
    );
  } else {
    return text;
  }
};

const StyledRenderDiv = styled.div`
  ${(props) => {
    if (!props.theme.palette?.chat) return "";
    const color =
      props.position === "right"
        ? props.theme.palette.chat.balloon.customer.color
        : props.sender === "AGENT"
        ? props.theme.palette.chat.balloon.agent.color
        : props.theme.palette.chat.balloon.bot.color;

    let ballonBackgroundColor =
      props.position === "right"
        ? props.theme.palette.chat.balloon.customer.backgroundColor
        : props.sender === "AGENT"
        ? props.theme.palette.chat.balloon.agent.backgroundColor
        : props.theme.palette.chat.balloon.bot.backgroundColor;

    let linkColor =
      props.position === "right"
        ? props.theme.palette.chat.balloon.customer.color
        : props.sender === "AGENT"
        ? props.theme.palette.chat.balloon.agent.color
        : props.theme.palette.chat.balloon.bot.color;

    // linkColor = chroma(linkColor).alpha(Number(props.theme.palette.chat.balloon.timeOpacity)).hex();
    const contrastDifference = chroma.contrast(ballonBackgroundColor, linkColor);

    const betterOptimalContrastRatio = 7; // WCAG
    const maximumContrastRatio = 12; // WCAG

    if (contrastDifference < betterOptimalContrastRatio) {
      const diffValue = betterOptimalContrastRatio - contrastDifference;
      linkColor = chroma(linkColor).brighten(diffValue).hex();
    } else if (contrastDifference > maximumContrastRatio) {
      const diffValue = contrastDifference - maximumContrastRatio;
      linkColor = chroma(linkColor).darken(diffValue).hex();
    }
    return `  
        .markdown-wrapper { 
          .markdown-body {
            --color-fg-default: ${color};
            --color-accent-fg: ${linkColor};
          }
        }
      `;
  }}
`;

/**
 * @param {string} text
 * @param {"html" | "markdown" | "plain"} format
 * @param sender
 * @param options
 */
export const renderText = (text, format = "plain", options = {}) => {
  return (
    <StyledRenderDiv position={options.position} sender={options.sender}>
      {renderTextContent(text, format, options)}
    </StyledRenderDiv>
  );
};
