import React, { forwardRef, useCallback, useEffect, useMemo, useRef, useState } from "react";

import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { FaCss3Alt, FaHtml5 } from "react-icons/fa";
import { LiaMarkdown } from "react-icons/lia";
import { MdTitle } from "react-icons/md";
import { Alert, FormFeedback, Input } from "reactstrap";

import CodeEditor from "~components/CodeEditor";
import ConstantHelper from "~helpers/ConstantHelper";
import RenderHelper from "~helpers/RenderHelper";
import SanitizeHelper from "~helpers/SanitizeHelper";

import PRButton from "../PRButton";
import PRSelect from "../PRSelect";

import "./style.scss";

const svgCode = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
  <path fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" d="M5 10h10"/>
</svg>`;

const dataIndeterminateUrl = `data:image/svg+xml;base64,${btoa(svgCode)}`;

const PRInput = forwardRef(function PRInput(
  {
    innerRef,
    invalid,
    className,
    classNameInput,
    onBlur,
    type = "text",
    value: valueProp,
    checked: checkedProp,
    size,
    bsSize,
    borderless,
    indeterminate,
    onChange,
    disabled,
    on: _on,
    defaultValue,
    ...rest
  },
  ref
) {
  const shiftState = useRef(false);
  const handleBlur = useCallback(
    (e) => {
      onBlur?.(e);
    },
    [onBlur]
  );
  const isIndeterminate = indeterminate && type === "checkbox";
  let value = valueProp;
  let checked = checkedProp;
  if (onChange && defaultValue === undefined) {
    if (["email", "text", "textarea"].includes(type)) {
      value = value || "";
    } else if (["number"].includes(type)) {
      value = value ?? 0;
    } else if (["checkbox"].includes(type)) {
      checked = isIndeterminate || (checked ?? false);
    }
  }
  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === "Shift") {
        shiftState.current = true;
      }
    };
    const handleKeyUp = (e) => {
      if (e.key === "Shift") {
        shiftState.current = false;
      }
    };
    document.addEventListener("keydown", handleKeyDown);
    document.addEventListener("keyup", handleKeyUp);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
      document.removeEventListener("keyup", handleKeyUp);
    };
  }, []);

  const handleOnChange = useCallback(
    (e) => {
      Object.defineProperty(e, "shiftKey", {
        get: () => shiftState.current,
      });

      onChange?.(e);
    },
    [onChange]
  );

  return (
    <div
      className={classNames(
        "pr-input",
        {
          borderless: borderless,
          disabled: disabled,
        },
        className
      )}
    >
      <Input
        {...rest}
        ref={ref}
        bsSize={size || bsSize}
        checked={checked}
        className={classNames(classNameInput, "pr-input__input", {
          "mt-0": "switch" === type,
        })}
        defaultValue={defaultValue}
        disabled={disabled}
        innerRef={innerRef}
        invalid={!!invalid}
        style={{
          ...(isIndeterminate && {
            backgroundImage: `url(${dataIndeterminateUrl})`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
          }),
        }}
        type={type}
        value={value}
        onBlur={handleBlur}
        onChange={handleOnChange}
      />
      {!!invalid && <FormFeedback type="invalid">{invalid}</FormFeedback>}
    </div>
  );
});
export default PRInput;

//TODO: Consider to support this:
//<div className="form-floating">
//  <input type="text" className="form-control" id="floatingInput" placeholder="Label" />
//  <label htmlFor="floatingInput">Label</label>
//</div>;

const [PRTextAreaFormat, PRTextAreaFormatOptions, PRTextAreaFormatMap] = ConstantHelper.typify({
  /** @type {"plain"} */
  plain: "Text",
  /** @type {"html"} */
  html: "HTML",
  /** @type {"markdown"} */
  markdown: "Markdown",
  /** @type {"css"} */
  css: "CSS",
});
export { PRTextAreaFormat };

export const PRTextArea = forwardRef(function PRTextArea(
  {
    innerRef,
    formatSelector: formatSelectorProp,
    onBlur,
    onFocus,
    format,
    onFormatChange,
    className,
    classNameInput,
    onChange,
    onSanitize,
    invalid,
    editorMode,
    editorProps,
    excludeFormatList = [PRTextAreaFormat.css],
    previewWrapper: PreviewWrapperComp = "div",
    previewRender,
    toolbarComponent,
    ...rest
  },
  ref
) {
  const { t } = useTranslation();
  const [view, setView] = useState(format);
  // const [value, setValue] = useState(rest.value);
  const [previewToggle, setPreviewToggle] = useState(false);
  const [contentPreview, setContentPreview] = useState(PRTextAreaFormat.plain);

  const formatSelector = !!format || !!onFormatChange || formatSelectorProp;
  const [sanitizedValue, setSanitizedValue] = useState(rest.value);
  const [showSanitizeWarning, setShowSanitizeWarning] = useState(false);
  const [codeEditor, setCodeEditor] = useState(null);

  const excludedPRTextAreaFormatOptions = useMemo(() => {
    return PRTextAreaFormatOptions.filter((option) => !excludeFormatList?.includes(option.value)).map((option) => ({
      ...option,
      label: (
        <div className="d-flex align-items-center">
          {option.value === PRTextAreaFormat.markdown && <LiaMarkdown className="me-2" style={{ color: "#455a64" }} />}
          {option.value === PRTextAreaFormat.html && <FaHtml5 className="me-2" style={{ color: "#e34f26" }} />}
          {option.value === PRTextAreaFormat.plain && <MdTitle className="me-2 color-secondary" />}
          {option.value === PRTextAreaFormat.css && <FaCss3Alt className="me-2 color-primary" />}

          {option.label}
        </div>
      ),
    }));
  }, [excludeFormatList]);

  const handleViewChange = useCallback(
    (newView) => {
      setView(newView);
      onFormatChange?.(newView);
    },
    [onFormatChange]
  );

  const handlePreviewToggle = useCallback(() => {
    setPreviewToggle((prev) => !prev);
  }, []);

  useEffect(() => {
    if (view === PRTextAreaFormat.markdown) {
      const rendered = RenderHelper.renderMd(rest.value || "", {
        html: true,
      });
      setContentPreview(rendered);
    } else {
      // HTML
      setContentPreview(rest.value);
    }
    if (view !== PRTextAreaFormat.html) {
      setShowSanitizeWarning(false);
    }
  }, [rest.value, view]);

  useEffect(() => {
    if (format !== view) {
      setView(format);
    }
  }, [format, view]);

  const handleOnChange = useCallback(
    (e) => {
      if (view === PRTextAreaFormat.html) {
        const sanitizedValue = SanitizeHelper.html(e.target.value);
        setSanitizedValue(sanitizedValue);
        setShowSanitizeWarning(false);
      }
      onChange?.(e);
    },
    [onChange, view]
  );

  const handleOnFocus = useCallback(
    (isFocus) => (e) => {
      if (isFocus) {
        onFocus?.(e);
      } else {
        setShowSanitizeWarning(true);
        onBlur?.(e);
      }
    },
    [onBlur, onFocus]
  );

  const handleSanitize = useCallback(() => {
    if (view !== PRTextAreaFormat.html) {
      onChange?.({ target: { name: rest.name, value: sanitizedValue } });
    } else {
      //apply to monaco editor to able to ctrl+z
      const change = {
        range: codeEditor.getModel().getFullModelRange(),
        text: sanitizedValue,
      };
      codeEditor.executeEdits("", [change]);
    }
    onSanitize?.(sanitizedValue);
  }, [onSanitize, sanitizedValue, onChange, rest.name, view, codeEditor]);

  const handleChangeEditor = useCallback(
    (value) => {
      if (rest.value !== value) {
        if (view === PRTextAreaFormat.html) {
          const sanitizedValue = SanitizeHelper.html(value);
          setSanitizedValue(sanitizedValue);
          setShowSanitizeWarning(false);
        }
        onChange?.({ target: { name: rest.name, value } });
      }
    },
    [onChange, rest.name, rest.value, view]
  );

  const handleEditorMount = useCallback(
    ({ editor }) => {
      setCodeEditor(editor);
      editorProps?.onMount?.(editor);
      editorProps?.editorProps?.onMount?.(editor);
    },
    [editorProps]
  );

  useEffect(() => {
    if (!codeEditor || view !== PRTextAreaFormat.html) return;

    const editor = codeEditor;
    const disposables = [];

    disposables.push(
      editor.onDidBlurEditorWidget(() => {
        setShowSanitizeWarning(true);
        onBlur?.();
      })
    );
    disposables.push(
      editor.onDidFocusEditorWidget(() => {
        onFocus?.();
      })
    );

    return () => {
      disposables.forEach((d) => d.dispose());
    };
  }, [codeEditor, view, onBlur, onFocus]);

  const { editorPropsExceptOnMount, inlineEditorPropsExceptOnMount } = useMemo(() => {
    const { onMount: _, ...rest } = editorProps || {};
    const { onMount: __, ...restInline } = editorProps?.editorProps || {};
    return { editorPropsExceptOnMount: rest, inlineEditorPropsExceptOnMount: restInline };
  }, [editorProps]);
  return (
    <div ref={ref} className={classNames("pr-input-textarea markdown-wrapper", className)} disabled={rest.disabled}>
      {!!formatSelector && (
        <div
          className={classNames("d-flex p-1 format-buttons gap-1 justify-content-between", {
            invalid: !!invalid,
          })}
        >
          <span className="d-flex gap-1">
            <PRSelect
              isPrimitiveValue
              noBorder
              isClearable={false}
              isDisabled={excludedPRTextAreaFormatOptions.length === 1}
              options={excludedPRTextAreaFormatOptions}
              value={view}
              onChange={handleViewChange}
            />

            {view === PRTextAreaFormat.html && sanitizedValue !== rest.value && showSanitizeWarning && (
              <span className="font-size-11 d-flex gap-1 align-items-center">
                <Alert className="px-2 py-1 m-0 d-flex align-items-center" color="warning">
                  {t("component.prInput.sanitizeWarning")}
                  <PRButton outline className="ms-2 px-2 py-1" color="secondary" size="sm" onClick={handleSanitize}>
                    {t("common.apply")}
                  </PRButton>
                </Alert>
              </span>
            )}
          </span>
          {[PRTextAreaFormat.html, PRTextAreaFormat.markdown].includes(view) && (
            <PRButton
              noBorder
              outline
              active={previewToggle}
              className="fw-bold"
              color="secondary"
              onClick={handlePreviewToggle}
            >
              {t("component.prInput.preview")}
            </PRButton>
          )}
          {toolbarComponent}
        </div>
      )}
      {previewToggle &&
        (previewRender ? (
          <div
            className={classNames("preview-container", {
              plaintext: view === PRTextAreaFormat.plain,
              "markdown-body": view === PRTextAreaFormat.markdown,
            })}
          >
            {previewRender(rest.value, view)}
          </div>
        ) : (
          <PreviewWrapperComp
            className={classNames("preview-container", {
              plaintext: view === PRTextAreaFormat.plain,
              "markdown-body": view === PRTextAreaFormat.markdown,
            })}
          >
            {view === PRTextAreaFormat.plain ? (
              contentPreview
            ) : (
              <div dangerouslySetInnerHTML={{ __html: contentPreview }} />
            )}
          </PreviewWrapperComp>
        ))}
      <div
        className={classNames(className, {
          "button-enabled": formatSelector,
          "d-none": previewToggle,
        })}
      >
        {editorMode ? (
          <CodeEditor
            {...rest}
            // value={slotValidationText}
            wordWrap
            defaultLanguage={
              view === PRTextAreaFormat.markdown
                ? "markdown"
                : view === PRTextAreaFormat.html
                ? "html"
                : view === PRTextAreaFormat.css
                ? "css"
                : "plaintext"
            }
            editorProps={{
              onFocus: handleOnFocus(true),
              onBlur: handleOnFocus(false),
              ...inlineEditorPropsExceptOnMount,
            }}
            invalid={invalid}
            language={
              view === PRTextAreaFormat.markdown
                ? "markdown"
                : view === PRTextAreaFormat.html
                ? "html"
                : view === PRTextAreaFormat.css
                ? "css"
                : "plaintext"
            }
            lineNumbers={false}
            minimap={false}
            noToolbar={PRTextAreaFormat.plain === view}
            theme={PRTextAreaFormat.plain === view && "light"}
            onChange={handleChangeEditor}
            onMount={handleEditorMount}
            {...editorPropsExceptOnMount}
          />
        ) : (
          <PRInput
            {...rest}
            classNameInput={classNameInput}
            innerRef={innerRef}
            invalid={invalid}
            type="textarea"
            onBlur={handleOnFocus(false)}
            onChange={handleOnChange}
            onFocus={handleOnFocus(true)}
          />
        )}
      </div>
    </div>
  );
});
