import {
  Fragment,
  forwardRef,
  memo,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";

import classNames from "classnames";
import { get, isEqual, uniq } from "lodash";
import { useTranslation } from "react-i18next";
import { FaSort, FaSortDown, FaSortUp } from "react-icons/fa";
import { TbDatabaseX } from "react-icons/tb";
import { useSelector } from "react-redux";
import { Col, Label, Row, Spinner, Table } from "reactstrap";

import styled from "@emotion/styled";

import { ReactComponent as SettingsIcon } from "~assets/images/icons/settings.svg";
import { useDebouncedEffect } from "~common/hooks/useDebounceEffect";
import useLoading from "~common/hooks/useLoading";
import useQueryParams from "~common/hooks/useQueryParams";
import PalIconButton from "~components/mui/PalIconButton";
import PalSelect from "~components/mui/PalSelect";
import PalFilter from "~components/PalFilter";
import HistoryHelper from "~helpers/HistoryHelper";
import Network from "~helpers/Network";
import Utils from "~helpers/Utils";
import { selectCurrentProject } from "~store/user/selectors";

import PRButton from "../PRButton";
import { usePRContainerContext } from "../PRContainer";
import PRInput from "../PRInput";
import PRPagination from "../PRPagination";

import "./style.scss";

const visibleOptions = [
  { value: 10, label: 10 },
  { value: 20, label: 20 },
  { value: 50, label: 50 },
  { value: 100, label: 100 },
  { value: 200, label: 200 },
  { value: 500, label: 500 },
];

const StyledIconButton = styled(PalIconButton)`
  &.MuiIconButton-root {
    width: 36px;
    height: 36px;
    padding: 0;
    svg {
      width: 18px;
      height: 18px;
    }
    border-radius: 4px;
    border: solid 1px #d0d0d0;
    background-color: #fafafa;
  }
  &.MuiIconButton-sizeSmall {
    // use bootstrap button sm
    height: 27px;
    width: 27px;
    padding: 0.25rem;
  }
`;

const FooterPalSelect = styled(PalSelect)`
  .MuiInputBase-root,
  .MuiInputBase-input {
    min-height: 26px !important;
    min-width: 60px !important;
    /* border-radius: 4px !important; */
    padding: 1px 6px !important;

    font-size: 15px;
    font-weight: 500;
    line-height: 20px;
  }
`;

const CustomPalFilterButton = function CustomPalFilterButton(props) {
  return (
    <StyledIconButton {...props}>
      <SettingsIcon />
    </StyledIconButton>
  );
};
const PRTable = memo(
  forwardRef(function PRTable(
    {
      id,
      label,
      bordered = true,
      noWrap,
      condensed = true,
      centered = true,
      borderless,
      striped,
      inverse,
      hover,
      reflow,
      responsive,
      className,
      loadingProp,
      columns = [],
      data: dataProp = [],
      size = "md",
      noPagination,
      noCount,
      onQueryChange,
      countProp,
      url,
      onLoad, // use memoized function (useCallback)
      urlQuery,
      responseDataKey = "results",
      responseDataCountKey = "count",
      queryDebounce = 250,
      memoizeAsQuery = true,
      filters,
      sorts,
      storageKey,
      rowRenderer,
      rowSelectorKey = "id",
      selectedRowItem,
      selectedRows,
      onRowClick,
      onRowChecked,
      onSelectAll,
      onSelectRange,
      inline,
      defaultSortInfo,
      customSetActions, //use memoized function (useCallback)
    },
    ref
  ) {
    const { t } = useTranslation();
    const { l: pageLength } = useQueryParams();
    const [tempUrlQuery, setTempUrlQuery] = useState(urlQuery);
    const [filterItems, setFilterItems] = useState([]);
    const [filterQuery, setFilterQuery] = useState({});
    const [remoteData, setRemoteData] = useState([]);
    const [remoteCount, setRemoteCount] = useState(0);
    const [filterOptionRequestDone, setFilterOptionRequestDone] = useState(false);
    const [visibleOption, setVisibleOption] = useState(
      (memoizeAsQuery && visibleOptions.find((i) => i.value === Number(pageLength))) || visibleOptions[0]
    );
    const currentProject = useSelector(selectCurrentProject);
    const keyboardStateRef = useRef({ shift: false });

    const { setActions } = usePRContainerContext();

    const orderedColumns = useMemo(() => {
      const newColumns = [...columns];
      newColumns.sort((a, b) => {
        //Sort by actions first move to end of the list
        if (a.actions) return 1;
        if (b.actions) return -1;

        if (a.order && b.order) {
          return a.order - b.order;
        } else if (a.order) {
          return -1;
        } else if (b.order) {
          return 1;
        } else {
          return 0;
        }
      });
      return newColumns;
    }, [columns]);

    const filteredLocalData = useMemo(() => {
      let filtered = [...dataProp];
      const localFilters = filterItems?.filter((i) => i.localFilter);
      if (!localFilters?.length) return dataProp;
      for (const filterItem of localFilters) {
        if (filterItem.type === "options") {
          const arrayValue = Array.isArray(filterItem.value) ? filterItem.value : [filterItem.value];
          filtered = filtered.filter((item) => {
            const itemValue = get(item, filterItem.key);
            return arrayValue.includes(itemValue);
          });
        } else if (filterItem.type === "text") {
          const value = filterItem.value?.toLowerCase();
          filtered = filtered.filter((item) => {
            const itemValue = (get(item, filterItem.key) || "").toString();
            return itemValue?.toLowerCase().includes(value);
          });
        } else if (filterItem.type === "boolean") {
          const value = Boolean(filterItem.value);
          filtered = filtered.filter((item) => {
            const itemValue = Boolean(get(item, filterItem.key));
            return itemValue === value;
          });
        }
        //TODO: Add date,datetime,time filters for local data
      }
      return filtered;
    }, [dataProp, filterItems]);

    const [page, setPage] = useState(1);
    const [loadingRemote, q] = useLoading(false);
    const loading = loadingProp || loadingRemote;
    const count = countProp || remoteCount;
    const data = useMemo(() => {
      const arr = filteredLocalData?.length ? filteredLocalData : remoteData || [];

      return arr;
    }, [filteredLocalData, remoteData]);
    const dataCount = count || data.length || 0;
    const [rerender, setRerender] = useState(false);
    const [tempId, setTempId] = useState(id);
    const [filterOptions, setFilterOptions] = useState({ options: { filter_fields: [], ordering_fields: [] } });
    const [sortInfo, setSortInfo] = useState(defaultSortInfo || []);

    const isCheckable = typeof selectedRows === "function" || Array.isArray(selectedRows) || onRowChecked;
    const isClickable = typeof onRowClick === "function";
    const [visibleData, setVisibleData] = useState([...(data.slice(0, visibleOption.value) || [])]);

    useImperativeHandle(ref, () => ({
      refresh: (resetPagination) => {
        setRerender((p) => !p);
        if (resetPagination) {
          setPage(1);
        }
      },
      resetPagination: () => {
        setPage(1);
      },
      getVisibleRows: () => visibleData,
      setVisibleRows: (rows) => {
        setVisibleData(rows);
      },
    }));

    const handleChangeFilter = useCallback((filters, filterQuery) => {
      setFilterItems(filters);
      setFilterQuery(filterQuery);
    }, []);

    useEffect(() => {
      const handleKeyDown = (e) => {
        if (e.key === "Shift") {
          keyboardStateRef.current.shift = true;
        }
      };
      const handleKeyUp = (e) => {
        if (e.key === "Shift") {
          keyboardStateRef.current.shift = false;
        }
      };
      window.addEventListener("keydown", handleKeyDown);
      window.addEventListener("keyup", handleKeyUp);
      return () => {
        window.removeEventListener("keydown", handleKeyDown);
        window.removeEventListener("keyup", handleKeyUp);
      };
    }, []);

    const handleVisibleOption = useCallback(
      (e, option) => {
        if (memoizeAsQuery) {
          const currentSearch = new URLSearchParams(window.location.search);
          currentSearch.set("l", option.value);
          HistoryHelper.push({ pathname: window.location.pathname, search: currentSearch.toString() });
        }
        const pageCounts = Math.ceil(dataCount / option.value);
        if (page > pageCounts) {
          setPage(pageCounts);
        }
        setVisibleOption(option);
      },
      [memoizeAsQuery, dataCount, page]
    );
    const handleChangePage = useCallback((page) => {
      setPage(page);
    }, []);

    const handleRowRender = useCallback(
      (rowData, index, rowArray) => {
        const key = rowData?.id || index;
        const isChecked =
          typeof selectedRows === "function"
            ? selectedRows(rowData, index)
            : selectedRows?.includes(rowData[rowSelectorKey]);

        const isItemActive =
          typeof selectedRowItem === "function"
            ? selectedRowItem(rowData, index)
            : selectedRowItem === rowData[rowSelectorKey];

        const handleRowClick = () => {
          onRowClick?.(rowData[rowSelectorKey], !isChecked, rowData);
        };
        const handleRowChecked = (e) => {
          let isRangeSelectSuccess = false;
          if (keyboardStateRef.current.shift) {
            const rangeSelectedIdList = [];
            const visibleDataIndex = visibleData.findIndex((i) => i[rowSelectorKey] === rowData[rowSelectorKey]);
            let isTraverseDone = false;
            for (let index = visibleDataIndex; index > -1; index--) {
              const element = visibleData[index];
              const checkedStatus = selectedRows?.includes(element?.[rowSelectorKey]);
              if (checkedStatus === !isChecked) {
                isTraverseDone = true;
                break;
              }
              rangeSelectedIdList.push(element[rowSelectorKey]);
            }
            if (isTraverseDone && rangeSelectedIdList.length) {
              isRangeSelectSuccess = true;
              onSelectRange?.(rangeSelectedIdList, !isChecked, rowData);
              e.stopPropagation();
            }
          }
          if (!isRangeSelectSuccess) {
            onRowChecked?.(rowData[rowSelectorKey], !isChecked, rowData);
          }
        };

        const rowItem = (
          <tr
            key={key}
            className={classNames({
              "table-checked": isChecked,
              "table-active": isItemActive,
              clickable: isClickable,
            })}
            onClick={handleRowClick}
          >
            {isCheckable && (
              <td
                className={classNames({
                  "sticky-td-left": true,
                })}
                role="cell"
                style={{
                  verticalAlign: "middle",
                }}
              >
                <PRInput checked={isChecked} type="checkbox" onChange={handleRowChecked} />
              </td>
            )}
            {orderedColumns.map((column, columnIndex) => {
              const cellValue = get(rowData, column.key);
              const columnKey = key + "_" + (column?.id || columnIndex);
              return (
                <td
                  key={columnKey}
                  className={classNames(column.className, {
                    "sticky-td-left": column.fixed === "left",
                    "sticky-td-right": column.fixed === "right",
                    "py-1": column.actions,
                  })}
                  role="cell"
                  style={{
                    verticalAlign: "middle",
                    ...column.style,
                    ...(column.noWrap && { whiteSpace: "nowrap" }),
                  }}
                >
                  {column.render ? column.render(rowData, index, cellValue) : cellValue}
                </td>
              );
            })}
          </tr>
        );
        if (rowRenderer && typeof rowRenderer === "function") {
          const Comp = rowRenderer;
          return (
            <Fragment key={key}>
              <Comp index={index} row={rowData}>
                {rowItem}
              </Comp>
            </Fragment>
          );
        }
        return rowItem;
      },
      [rowRenderer, columns, isCheckable, selectedRows, rowSelectorKey, onRowClick, isClickable]
    );

    const handleSort = useCallback(
      (key) => (isShiftPressed) => {
        setSortInfo((prev) => {
          let newSortInfo = [...prev];
          if (!isShiftPressed) {
            newSortInfo = newSortInfo.filter((i) => i.key === key);
          }

          const index = newSortInfo.findIndex((i) => i.key === key);
          if (index === -1) {
            newSortInfo.push({ key, order: "asc" });
          } else {
            if (newSortInfo[index].order === "asc") {
              newSortInfo[index].order = "desc";
            } else {
              newSortInfo.splice(index, 1);
            }
          }
          return newSortInfo;
        });
      },
      []
    );

    useDebouncedEffect(
      () => {
        if (!isEqual(tempUrlQuery, urlQuery)) {
          setTempUrlQuery(urlQuery);
        }
      },
      [urlQuery, tempUrlQuery],
      queryDebounce
    );

    useEffect(() => {
      if (isEqual(tempId, id)) return;
      setTempId(id);
      setRerender((p) => !p);
    }, [tempId, id]);

    useEffect(() => {
      const abortController = new AbortController();
      if (!url || !filterOptionRequestDone) return;
      const requestParams = {
        ...filterQuery,
        ...(noPagination ? {} : Utils.getPaging(page, visibleOption?.value)),
        ...(!!sortInfo.length && { ordering: sortInfo.map((i) => (i.order === "desc" ? "-" : "") + i.key).join(",") }),
        ...tempUrlQuery,
      };
      const promise = Network.request(url, {
        method: "GET",
        params: requestParams,
        signal: abortController.signal,
      })
        .then(async (res) => {
          let responseData = res;
          let count;

          if (responseDataKey) {
            responseData = get(res, responseDataKey, []);
          }

          if (responseDataCountKey) {
            count = get(res, responseDataCountKey, 0);
          }

          if (count === undefined) {
            count = responseData?.length || 0;
          }
          if (onLoad) {
            const newResponseData = await onLoad(responseData);
            if (newResponseData) {
              responseData = newResponseData;
            }
          }
          setRemoteData(responseData);
          setRemoteCount(count);
        })
        .catch((err) => {
          if (err?.message === "canceled") return;
          console.log(err);
        });
      q(promise);
      return () => {
        abortController.abort();
      };
    }, [onLoad, responseDataCountKey, filterOptionRequestDone, url, tempUrlQuery, page, visibleOption?.value, rerender, responseDataKey, q, filterQuery, sortInfo, noPagination]);

    useEffect(() => {
      const abortController = new AbortController();
      if (!url) {
        setFilterOptionRequestDone(true);
        return;
      }
      const promise = Network.request(url, {
        method: "OPTIONS",
        signal: abortController.signal,
      })
        .then((res) => {
          setFilterOptions(res);
        })
        .catch((err) => {
          if (err?.message === "canceled") return;
          console.log(err);
        })
        .finally(() => {
          setFilterOptionRequestDone(true);
        });
      q(promise);
      return () => {
        abortController.abort();
      };
    }, [url, q]);

    useEffect(() => {
      const limit = visibleOption?.value;
      onQueryChange?.(Utils.getPaging(page, limit));
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, visibleOption?.value]);
    useEffect(() => {
      if (noPagination || count) {
        setVisibleData([...data]);
        return;
      }
      const paginatedData = [...(data.slice((page - 1) * visibleOption.value, page * visibleOption.value) || [])];
      setVisibleData(paginatedData);
    }, [page, visibleOption, data, noPagination, count]);

    const mergedFilters = useMemo(() => {
      const concatenatedFilters = [];
      if (!filterOptionRequestDone) return concatenatedFilters;
      const mergedUniqueKeys = uniq([
        ...(filters?.map((i) => i.key) || []),
        ...(filterOptions?.options?.filter_fields?.map((i) => i.key) || []),
      ]);
      const maxLoopLength = mergedUniqueKeys?.length || 0;
      for (let i = 0; i < maxLoopLength; i++) {
        const key = mergedUniqueKeys[i];
        const filter = filters?.find((i) => i.key === key);
        const filterOption = filterOptions?.options?.filter_fields?.find((i) => i.key === key);
        let filterObject;
        if (filter && filterOption) {
          filterObject = { ...filterOption, ...filter };
        } else if (filter) {
          filterObject = filter;
        } else if (filterOption) {
          filterObject = filterOption;
        }
        if (filterObject) {
          if (filterObject.default_lookup_key && !filterObject.lookup_keys?.includes(filterObject.default_lookup_key)) {
            filterObject.lookup_keys = [filterObject.default_lookup_key, ...filterObject.lookup_keys];
          }
          //Remove exact lookup key if in lookup key is available for options
          //TODO: This is a temporary solution. We need to remove exact lookup key from backend
          if (["in", "exact"].every((lk) => filterObject.lookup_keys?.includes(lk))) {
            filterObject.lookup_keys = ["in"];
          }
          concatenatedFilters.push(filterObject);
        }
      }
      const columnKeyArray = columns.map((i) => i.key);

      const sortedFilters = concatenatedFilters.sort((a, b) => {
        if (a.order && b.order) {
          return a.order - b.order;
        } else if (a.order) {
          return -1;
        } else if (b.order) {
          return 1;
        } else {
          const aIndex = columnKeyArray.indexOf(a.key);
          const bIndex = columnKeyArray.indexOf(b.key);
          if (aIndex === -1 && bIndex === -1) {
            return 0;
          }
          if (aIndex === -1) {
            return 1;
          }
          if (bIndex === -1) {
            return -1;
          }
          return aIndex - bIndex;
        }
      });

      return sortedFilters;
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filters, filterOptions, filterOptionRequestDone]);

    const isHeaderExist = columns.some((i) => i.label);

    const { isAllSelected, isAllVisibleSelected, visibleDataIdList, selectedIdList, visibleDataSelectedObjectList } =
      useMemo(() => {
        const visibleDataIdList = visibleData?.map((i) => i?.[rowSelectorKey]);

        let selectedIdList = [];
        const selectedRowObjects = [];
        if (typeof selectedRows === "function") {
          visibleData?.forEach((row, index) => {
            if (selectedRows?.(row, index)) {
              selectedIdList.push(row?.[rowSelectorKey]);
              selectedRowObjects.push(row);
            }
          });
        } else {
          const rows = selectedRows || [];
          selectedIdList = [...rows];
          selectedRowObjects.push(...visibleData.filter((row) => rows?.includes(row?.[rowSelectorKey])));
        }

        const isAllVisibleSelected =
          visibleDataIdList?.length && visibleDataIdList.every((i) => selectedIdList?.includes(i));
        const isAllSelected = dataCount > 0 && dataCount === selectedIdList?.length;
        return {
          isAllSelected,
          isAllVisibleSelected,
          visibleDataIdList,
          selectedIdList,
          visibleDataSelectedObjectList: selectedRowObjects,
        };
      }, [visibleData, rowSelectorKey, selectedRows, dataCount]);

    const handleCheckUncheckAll = useCallback(
      (e) => {
        const isInvert = e?.shiftKey;
        let selectedAllList = [...selectedIdList];
        if (isInvert) {
          const nonVisibleSelectedIdList = selectedIdList.filter((i) => !visibleDataIdList?.includes(i));
          const visibleUnselectedIdList = visibleDataIdList.filter((i) => !selectedIdList?.includes(i));
          selectedAllList = [...nonVisibleSelectedIdList, ...visibleUnselectedIdList];
        } else if (isAllVisibleSelected) {
          const nonVisibleSelectedIdList = selectedIdList.filter((i) => !visibleDataIdList?.includes(i));
          selectedAllList = [...nonVisibleSelectedIdList];
        } else {
          selectedAllList.push(...visibleDataIdList);
        }

        selectedAllList = uniq(selectedAllList);

        onSelectAll?.(selectedAllList);
      },
      [visibleDataIdList, selectedIdList, isAllVisibleSelected, onSelectAll]
    );

    const isIndeterminate = useMemo(() => {
      return !isAllSelected && selectedIdList?.length;
    }, [isAllSelected, selectedIdList]);
    const handleClearAll = useCallback(() => {
      onSelectAll?.([]);
    }, [onSelectAll]);

    useEffect(() => {
      if (mergedFilters?.length && filterOptionRequestDone && (filters || url)) {
        const setter = customSetActions || setActions;
        setter?.([
          {
            component: PalFilter,
            renderButton: CustomPalFilterButton,
            onChange: handleChangeFilter,
            filters: mergedFilters,
            // storageKey: !!filterOptionRequestDone && `${storageKey}_${currentProject?.id}`,
            storageKey: `${storageKey}_${currentProject?.id}`,
          },
        ]);
      }
    }, [filters, url, setActions, customSetActions, handleChangeFilter, mergedFilters, filterOptionRequestDone, storageKey, currentProject]);

    const { viewingStart, viewingEnd, totalCount } = useMemo(() => {
      const viewingStart = (page - 1) * visibleOption.value + 1;
      const viewingEnd = Math.min(page * visibleOption.value, dataCount);
      const totalCount = dataCount;
      return { viewingStart, viewingEnd, totalCount };
    }, [page, visibleOption, dataCount]);

    useEffect(() => {
      const pageCounts = Math.ceil(dataCount / visibleOption.value);
      if (page > pageCounts && pageCounts > 0) {
        setPage(1);
      }
    }, [totalCount, visibleOption, page, dataCount]);

    return (
      <div
        className={classNames("pr-table", className, {
          inline,
          "mt-2": !(mergedFilters?.length > 0) && inline, //remove padding's space
        })}
      >
        {label && (
          <Row>
            <Col md>
              <Label className="">{label}</Label>
            </Col>
          </Row>
        )}
        <div
          className={classNames("table-wrapper position-relative", {
            "table-loading": loading,
          })}
        >
          <div className={classNames("pr-spinner")}>
            <Spinner className="ms-2" color="primary" />
          </div>
          <Table
            bordered={bordered}
            borderless={borderless}
            className={classNames("mb-0", "table table-responsive", {
              "table-centered": centered,
              "table-nowrap": noWrap,
              "table-condensed": condensed,
              "table-sm": size === "sm",
              // borderless: inline,
              // striped: inline,
            })}
            hover={hover || isCheckable}
            inverse={inverse}
            reflow={reflow}
            responsive={responsive}
            striped={striped}
          >
            {isHeaderExist && (
              <thead className="table-light table-nowrap">
                <tr role="row">
                  {isCheckable && (
                    <th
                      className={classNames({
                        "sticky-th-left": true,
                      })}
                      style={{ width: "1%" }}
                    >
                      <div className="d-flex align-items-center">
                        <div className="">
                          <PRInput
                            checked={isAllSelected}
                            type="checkbox"
                            onChange={handleCheckUncheckAll}
                            // className="form-check-input"
                            indeterminate={isIndeterminate}
                          />
                        </div>
                      </div>
                    </th>
                  )}
                  {orderedColumns.map((column, index, arr) => {
                    const orderingInfo = sorts || filterOptions?.options?.ordering_fields || [];
                    const isSortable = !!column.sort || orderingInfo.includes(column.key);
                    const columnSortInfo = sortInfo.find((i) => i.key === column.key);

                    const handleClickSort = (e) => {
                      const isShiftPressed = e.shiftKey;
                      e.stopPropagation();
                      if (isSortable) {
                        handleSort(column.key)(isShiftPressed);
                      }
                    };
                    return (
                      <th
                        key={index}
                        className={classNames(column.className, {
                          "sticky-th-left": column.fixed === "left",
                          "sticky-th-right": column.fixed === "right",
                          "no-after": arr?.[index + 1]?.fixed,
                        })}
                        style={{
                          ...column.style,
                          ...(!!column.actions && { width: "1%" }),
                        }}
                      >
                        <div className="d-flex align-items-center">
                          <div className="" onClick={handleClickSort}>
                            {column.label}
                          </div>
                          {isSortable && (
                            <div className="mx-1 center-container" onClick={handleClickSort}>
                              {columnSortInfo?.order === "asc" && (
                                <FaSortUp className="mx-2 fs-4 center-item sort-active sort-up-down" />
                              )}
                              {columnSortInfo?.order === "desc" && (
                                <FaSortDown className="mx-2 fs-4 center-item sort-active sort-up-down" />
                              )}
                              <FaSort className="mx-2 fs-4 center-item sort-passive" />
                            </div>
                          )}
                        </div>
                      </th>
                    );
                  })}
                </tr>
              </thead>
            )}
            <tbody role="rowgroup">
              {visibleData.map((data, rowIndex) => handleRowRender(data, rowIndex, visibleData))}
              {!visibleData.length && (
                <tr
                  className={classNames("align-middle", {
                    // "opacity-75": loading,
                  })}
                  style={{ height: 80 }}
                >
                  <td className="text-secondary text-center" colSpan={columns.length}>
                    {loading ? (
                      <>{/* <TbDatabaseX className=" ms-1 fs-5" /> */}</>
                    ) : (
                      <>
                        <TbDatabaseX className="me-1 fs-5" />
                        <Label className="my-2">{t("component.prTable.noData")}</Label>
                      </>
                    )}
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </div>
        {(!noPagination || !noCount) && (
          <div className="table-footer">
            <Row className="table-footer-inline align-items-center gx-2">
              {!noPagination && (
                <Col lg className="" xs="6">
                  <Row className=" flex-nowrap align-items-center gx-2 justify-content-start ">
                    <Col className="d-none d-md-inline" xs="auto">
                      <span className="table-footer-text">{t("component.prTable.view1")}</span>
                    </Col>
                    <Col xs="auto">
                      <div className="table-footer-select">
                        <FooterPalSelect
                          noWrap
                          borderRadius={4}
                          className="table-footer-text"
                          isClearable={false}
                          options={visibleOptions}
                          value={visibleOption}
                          onChange={handleVisibleOption}
                        />
                      </div>
                    </Col>
                    <Col className="d-none d-md-inline" xs="auto">
                      <span className="table-footer-text">{t("component.prTable.view2")}</span>
                    </Col>
                  </Row>
                </Col>
              )}
              <Col className="table-footer-text  justify-content-end" lg="auto" xs="6">
                {!noCount && (
                  <div className="d-flex align-items-center">
                    <div>
                    {t("component.prTable.showingElements").format(
                      Math.min(
                      viewingStart,
                      totalCount
                    ).toString(),
                      viewingEnd.toString(),
                      totalCount.toString()
                    )}
                  </div>
                  </div>
                )}
              </Col>
              {!noPagination && (
                <Col lg="auto" xs="12">
                  <PRPagination
                    className="ms-1"
                    pageSize={visibleOption.value}
                    range={8}
                    total={dataCount}
                    value={page}
                    onChange={handleChangePage}
                  />
                </Col>
              )}
              {isCheckable && !!selectedIdList?.length && (
                <Col lg="12" xs="12">
                  <>
                    <span className="mb-1 mt-2 d-flex align-items-center table-footer-text">
                      <span className="">Selected: {selectedIdList.length}</span>
                      <PRButton className="ms-1 p-0" color="link" onClick={handleClearAll}>
                        Clear
                      </PRButton>
                    </span>
                  </>
                </Col>
              )}
            </Row>
          </div>
        )}
      </div>
    );
  })
);
export default PRTable;
