import ConstantHelper from "~helpers/ConstantHelper";

export const userRole = {
  /** @type {"Admin"} */
  admin: "Admin",
  /** @type {"ChatbotModerator"} */
  chatbotModerator: "ChatbotModerator",
  /** @type {"CallCenterAgent"} */
  callCenterAgent: "CallCenterAgent",
  /** @type {"TicketAgent"} */
  ticketAgent: "TicketAgent",
  /** @type {"OrganizationManager"} */
  organizationManager: "OrganizationManager",
  /** @type {"AgentManager"} */
  AgentManager: "AgentManager",
  /** @type {"ResourceManager"} */
  ResourceManager: "ResourceManager",
};

export const [__, userRoleOptions, userRoleMap] = ConstantHelper.typify({
  /** @type {"Admin"} */
  Admin: "layout.profile.admin",
  /** @type {"ChatbotModerator"} */
  ChatbotModerator: "layout.profile.chatbotModerator",
  /** @type {"CallCenterAgent"} */
  CallCenterAgent: "layout.profile.callCenterAgent",
  /** @type {"TicketAgent"} */
  TicketAgent: "layout.profile.ticketAgent",
  /** @type {"OrganizationManager"} */
  OrganizationManager: "layout.profile.organizationManager",
  /** @type {"AgentManager"} */
  AgentManager: "layout.profile.agentManager",
  /** @type {"ResourceManager"} */
  ResourceManager: "layout.profile.resourceManager",
  
});

// export const userRoleMap = {
//   [userRole.admin]: "layout.profile.admin",
//   [userRole.chatbotModerator]: "layout.profile.chatbotModerator",
//   [userRole.callCenterAgent]: "layout.profile.callCenterAgent",
//   [userRole.ticketAgent]: "layout.profile.ticketAgent", //TODO: Check the translation for this
//   [userRole.organizationManager]: "layout.profile.organizationManager",
//   [userRole.AgentManager]: "layout.profile.agentManager",
//   [userRole.ResourceManager]: "Reservation Manager",
// };

// export const userRoleOptions = [
//   { value: userRole.admin, label: userRoleMap[userRole.admin] },
//   { value: userRole.chatbotModerator, label: userRoleMap[userRole.chatbotModerator] },
//   { value: userRole.callCenterAgent, label: userRoleMap[userRole.callCenterAgent] },
//   { value: userRole.ticketAgent, label: userRoleMap[userRole.ticketAgent] },
//   { value: userRole.organizationManager, label: userRoleMap[userRole.organizationManager] },
//   { value: userRole.AgentManager, label: userRoleMap[userRole.AgentManager] },
//   { value: userRole.ResourceManager, label: userRoleMap[userRole.ResourceManager] },
// ];

export const [userAuthenticationMethod, userAuthenticationMethodOptions, userAuthenticationMethodMap] =
  ConstantHelper.typify({
    // none: "None",
    email: "Email",
    authenticator: "Authenticator",
  });
