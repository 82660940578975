import { useRef, useState, useEffect } from "react";

import { Box, Fade } from "@mui/material";

import AudioVisualizerHelper from "~helpers/AudioVisualizerHelper";

export default function VuMeter({ enable, width: widthProp }) {
  const canvasRef = useRef(null);
  const [boxWidth, setBoxWidth] = useState(0);
  const [wrapperRef, setWrapperRef] = useState(null);
  const [audioVisualizerInstance, setAudioVisualizerInstance] = useState(null);
  const [volumeStep, setVolumeStep] = useState(0);
  useEffect(() => {
    if (!canvasRef.current) return;
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    const numBars = 9;
    // const barWidth = 32;
    const barWidth = Math.floor((boxWidth - 32) / numBars); // Calculate width of each bar based on number of bars and total width
    const barGap = 4;
    const totalWidth = numBars * (barWidth + barGap) - barGap; // Calculate total width of all bars together
    const middleIndex = Math.floor(numBars / 2); // Find the middle bar index

    // Create a gradient across the entire set of bars
    const gradient = ctx.createLinearGradient(0, 0, totalWidth, 0);
    gradient.addColorStop(0, "#28aae1"); // Start color
    gradient.addColorStop(0.5, "#28ffe1"); // Middle color
    gradient.addColorStop(1, "#28aae1"); // End color

    // Function to draw a single bar
    const drawBar = (index, active) => {
      const x = index * (barWidth + barGap);
      ctx.fillStyle = active ? gradient : "#e0e0e0"; // Use gradient for active bars, grey for inactive
      ctx.fillRect(x, 0, barWidth, canvas.height);
    };

    // Clear canvas
    ctx.clearRect(0, 0, canvas.width, canvas.height);

    // Determine which bars to activate
    for (let i = 0; i < numBars; i++) {
      // Activate bars symmetrically around the middle bar
      const isActive = Math.abs(i - middleIndex) < volumeStep;
      drawBar(i, isActive);
    }
  }, [volumeStep, boxWidth]);

  // const handleResize = () => {
  //   setBoxWidth(wrapperRef?.clientWidth - 40); // - 40 for padding
  // };

  useEffect(() => {
    if (!wrapperRef) return;
    setBoxWidth(wrapperRef?.clientWidth - 40);
    const observer = new ResizeObserver(() => {
      setBoxWidth(wrapperRef?.clientWidth - 40);
    });
    observer.observe(wrapperRef);
    return () => {
      observer.disconnect();
    };
  }, [wrapperRef]);

  useEffect(() => {
    const visualizer = new AudioVisualizerHelper();
    visualizer.initPromise.then(() => {
      setAudioVisualizerInstance(visualizer);
    });
    return () => {
      visualizer.dispose();
    };
  }, []);

  useEffect(() => {
    if (enable && audioVisualizerInstance) {
      audioVisualizerInstance.start({
        onVolumeChanged: (volume) => {
          //scale 0-100 to between 0-5
          setVolumeStep(Math.floor((volume / 100) * 5));
        },
      });
      return () => {
        audioVisualizerInstance.stop();
      };
    }
  }, [enable, audioVisualizerInstance]);

  // if (!enable) return null;
  return (
    <Fade appear={true} in={enable}>
      <Box
        ref={setWrapperRef}
        sx={{
          position: "relative",
          padding: "10px 20px",
          margin: "0 20px",
          border: "1px solid #ddd",
          borderRadius: "5px",
          width: widthProp ?? 450 - 2 * 20,
          // marginBottom: "10px",
          backgroundColor: "#f8f8f8",
        }}
      >
        <Box
          sx={{
            borderRadius: "5px",
            overflow: "hidden",
          }}
          w={1}
        >
          <canvas ref={canvasRef} height="8" width={boxWidth}></canvas>
        </Box>
      </Box>
    </Fade>
  );
}
